import { UserDto } from '../../types/user';
import * as types from './types';

const initialState: any = {
  agents: [],
  allAgents: [],
  agentsLoading: false,
  agentsError: null,
  agentSaveSuccess: false,
  agentUpdateSuccess: false,
  softDeleteAgentSuccess: false,
  total: 0,
  groupsOfAgentLoading: false,
  groupsOfAgent: [],
  agentDeleteSuccess: false,
  followUpLoading: false,
};

export default function agentsReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.FORCE_DELETE_AGENT:
      return {
        ...state,
        agentsError: null,
        agentsLoading: true,
        agentDeleteSuccess: false,
      };
    case types.FORCE_DELETE_AGENT_SUCCESS:
      return {
        ...state,
        agentsLoading: false,
        agentDeleteSuccess: true,
      };
    case types.FORCE_DELETE_AGENT_FAIL:
      return {
        ...state,
        agentsLoading: false,
        agentDeleteSuccess: false,
        agentsError: action.payload,
      };
    case types.DELETE_AGENT:
      return {
        ...state,
        agentsError: null,
        agentsLoading: true,
        agentDeleteSuccess: false,
      };
    case types.DELETE_AGENT_SUCCESS:
      return {
        ...state,
        agentsLoading: false,
        agentDeleteSuccess: true,
      };
    case types.DELETE_AGENT_FAIL:
      return {
        ...state,
        agentsLoading: false,
        agentDeleteSuccess: false,
        agentsError: action.payload,
      };
    case types.ADD_AGENT:
    case types.UPDATE_AGENT:
      return {
        ...state,
        agentUpdateSuccess: false,
        agentSaveSuccess: false,
        agentsError: null,
        agentsLoading: true,
      };

    case types.ADD_AGENT_SUCCESS:
      const agentsCopy = [...state.agents];
      agentsCopy.length = agentsCopy.length - 1;
      agentsCopy.unshift(action.payload[0]);
       return {
        ...state,
        agents: agentsCopy,
        agentSaveSuccess: true,
        agentsLoading: false,
      };

    case types.UPDATE_AGENT_SUCCESS:
      const agents = state.agents.map((agent: UserDto) => {
        if (agent._id === action.payload[0]?._id) {
          return action.payload[0];
        }
        return agent;
      });
      return {
        ...state,
        agents,
        agentUpdateSuccess: true,
        agentsError: null,
        agentsLoading: false,
      };

    case types.ADD_AGENT_FAIL:
    case types.UPDATE_AGENT_FAIL:
      return {
        ...state,
        agentUpdateSuccess: false,
        agentsError: action.payload,
        agentsLoading: false,
      };

    case types.GET_AGENT:
      return {
        ...state,
        agentsLoading: true,
        agentsError: null,
      };

    case types.GET_AGENT_SUCCESS:
      return {
        ...state,
        agents: action.payload.data,
        total: action.payload.metadata[0].total,
        agentsLoading: false,
      };

    case types.GET_AGENT_FAIL:
      return {
        ...state,
        agentsError: action.payload,
        total: 0,
        agentsLoading: false,
        followUpLoading: false,
      };

    case types.GET_ALL_AGENTS:
      return {
        ...state,
        agentsLoading: true,
        agentsError: null,
      };

    case types.GET_ALL_AGENTS_SUCCESS:
      return {
        ...state,
        allAgents: action.payload.data,
        agentsLoading: false,
      };

    case types.GET_GROUPS_OF_AGENT:
      return {
        ...state,
        groupsOfAgentLoading: true,
        groupsOfAgent: [],
      };
    case types.GET_GROUPS_OF_AGENT_SUCCESS:
      return {
        ...state,
        groupsOfAgentLoading: false,
        groupsOfAgent: action.payload.data,
      };
    case types.GET_GROUPS_OF_AGENT_FAIL:
      return {
        ...state,
        groupsOfAgentLoading: false,
        groupsOfAgent: [],
        agentsError: action.payload,
      };
    case types.CLEAN_DEBOUNCE:
      return {
        ...state,
        groupsOfAgentLoading: false,
        groupsOfAgent: [],
        agentsError: null,
      };
    case types.SEND_FOLLOW_UP:
      return {
        ...state,
        followUpLoading: true,
      };
    case types.SEND_FOLLOW_UP_SUCCESS:
      return {
        ...state,
        followUpLoading: false,
      };

    default:
      return state;
  }
}
