import React, {useEffect, useState} from 'react';
import { DatePicker, Modal, Radio } from 'antd';
import { IObjectKeys } from '../../../../helpers/globalTypes';
import type { RadioChangeEvent, DatePickerProps } from 'antd';
import moment from 'moment';
import { getBrowser } from '../../../../helpers/globalFunctions';
import {useDispatch, useSelector} from "react-redux";
import {
  cancelAgentGroupSubscription, getAgentsGroups,
  setAgentGroupSubscription,
  setStatusToFalse
} from "../../../../store/agentsGroups/action";
import {subscriptionSuccessSelector} from "../../../../store/agentsGroups/selectors";
import {getUsers} from "../../../../store/users/action";
import {TablePaginationConfig} from "antd/lib/table/interface";
import Input from 'antd/lib/input/Input';

interface ISubscriptionModal {
  isModalVisible: boolean;
  closeModalHandler: () => void;
  agent: IObjectKeys;
}

const SubscriptionModal = ({
  isModalVisible,
  closeModalHandler,
  agent,
}: ISubscriptionModal) => {
  const [value, setValue] = useState(agent.subscription_level);
  const dispatch = useDispatch()
  const [datePickerValue, setDatePickerValue] = useState(
    +new Date(moment().add(1, 'M').format(getBrowser())),
  );
  const statusSuccess = useSelector(subscriptionSuccessSelector)
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [sortOptions, setSortOptions] = useState({
    sortOrder: 'desc',
    sortField: 'updated_at',
  });
  const [days, setDays] = useState(
    moment().add(1, 'M').diff(moment().startOf('day'), 'days'),
  );
  const onStatusChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
  };

  const onDateChange: DatePickerProps['onChange'] = (date, dateString) => {
    if (date) {
      const days = date.diff(moment().startOf('day'), 'days');
      setDays(days);
    }
    setDatePickerValue(+new Date(dateString));
  };

  const handleOk = () => {
    if (value === 'premium') {
      dispatch(setAgentGroupSubscription({trialDays:days,agencyId:agent._id}))
    } else {
      dispatch(cancelAgentGroupSubscription(agent._id))
    }
  };

  useEffect(()=>{
    if(statusSuccess){
      closeModalHandler()
      dispatch(setStatusToFalse())
      dispatch(
          getUsers(),
      );
      dispatch(
          getAgentsGroups(
              undefined,
              undefined,
              sortOptions.sortField,
              sortOptions.sortOrder,
          ),
      );
    }
  },[statusSuccess])
  return (
    <Modal
      title='Subscription Status'
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={() => { setValue(agent.subscription_level); closeModalHandler() }}
    >
      <div style={{ display: 'grid', gap: '20px' }}>
        <p style={{ marginBottom: '0' }}>Select Status</p>
        <Radio.Group onChange={onStatusChange} value={value}>
          <Radio value={'free'}>Free</Radio>
          <Radio value={'premium'}>Premium</Radio>
        </Radio.Group>
        {value === 'premium' && (
          <div className='premium-wrapper'>
            <DatePicker
              onChange={onDateChange}
              format={'MM-DD-YYYY'}
              defaultValue={moment().add(1, 'M')}
              disabledDate={(current) =>
                current && current < moment().endOf('day')
              }
            />
            <Input value={days} suffix='days' disabled />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SubscriptionModal;
