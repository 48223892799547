import React from 'react';
import { IObjectKeys } from '../../helpers/globalTypes';

export const ProfilesOfUser = ({ user }: IObjectKeys) => {
  let profiles: string[] = [];
  const arr = ['agent_id', 'landlord_id', 'renter_id'];
  arr.forEach((p: string) => {
    if (user[p]) {
      profiles.push(p.slice(0, -3));
    }
  });

  const str = profiles.length ? `user has ${profiles.join(', ')} profile${profiles.length === 1? '': 's'}` : (
    `no profile`
  )
  return (
    <>
      <span>{str}</span>
    </>
  );
};
