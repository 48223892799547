import { IFollowUpSend, UserModalFormData } from '../../components/Users/types';

import * as types from './types';

export function getGroupsOfAgent(
  size: number = 10,
  searchValue: string = '',
  agentsPage: boolean = true,
) {
  return {
    type: types.GET_GROUPS_OF_AGENT,
    payload: {
      size,
      searchValue,
      agentsPage,
    },
  };
}

export function getGroupsOfAgentSuccess(data: object) {
  return { type: types.GET_GROUPS_OF_AGENT_SUCCESS, payload: data };
}

export function getGroupsOfAgentFail(error: object) {
  return { type: types.GET_GROUPS_OF_AGENT_FAIL, payload: error };
}

export function cleanDebounce() {
  return { type: types.CLEAN_DEBOUNCE };
}

export function getAgents(
  page: number = 1,
  size: number = 10,
  sortField: string = 'updated_at',
  sortOrder: string = 'desc',
  searchValue: string = '',
  agentsOnly: boolean = true,
  agencyName?: boolean,
) {
  return {
    type: types.GET_AGENT,
    payload: {
      page,
      size,
      sortField,
      sortOrder,
      searchValue,
      agentsOnly,
      agencyName,
    },
  };
}

export function getAgentsSuccess(agents: object) {
  return { type: types.GET_AGENT_SUCCESS, payload: agents };
}

export function getAgentsFail(error: object) {
  return { type: types.GET_AGENT_FAIL, payload: error };
}

export function getAllAgents() {
  return {
    type: types.GET_ALL_AGENTS,
  };
}

export function getAllAgentsSuccess(agents: object) {
  return { type: types.GET_ALL_AGENTS_SUCCESS, payload: agents };
}

export function updateAgent(payload: UserModalFormData) {
  return { type: types.UPDATE_AGENT, payload };
}

export function updateAgentSuccess(payload: any) {
  return { type: types.UPDATE_AGENT_SUCCESS, payload };
}

export function updateAgentFail(payload: UserModalFormData) {
  return { type: types.UPDATE_AGENT_FAIL, payload };
}

export function forceDeleteAgent(payload: string) {
  return { type: types.FORCE_DELETE_AGENT, payload };
}

export function forceDeleteAgentSuccess(payload: any) {
  return { type: types.FORCE_DELETE_AGENT_SUCCESS, payload };
}

export function forceDeleteAgentFail(payload: UserModalFormData) {
  return { type: types.FORCE_DELETE_AGENT_FAIL, payload };
}

export function deleteAgent(payload: string) {
  return { type: types.DELETE_AGENT, payload };
}

export function deleteAgentSuccess(payload: any) {
  return { type: types.DELETE_AGENT_SUCCESS, payload };
}

export function deleteAgentFail(payload: UserModalFormData) {
  return { type: types.DELETE_AGENT_FAIL, payload };
}

export function addAgent(payload: UserModalFormData) {
  return { type: types.ADD_AGENT, payload };
}

export function addAgentSuccess(payload: any) {
  return { type: types.ADD_AGENT_SUCCESS, payload };
}

export function addAgentFail(payload: UserModalFormData) {
  return { type: types.ADD_AGENT_FAIL, payload };
}

export function resendConfirmationLink(payload: string) {
  return { type: types.RESEND_CONFIRMATION_LINK, payload };
}

export function resendConfirmationLinkSuccess(payload: any) {
  return { type: types.RESEND_CONFIRMATION_LINK_SUCCESS, payload };
}

export function resendConfirmationLinkFail(payload: UserModalFormData) {
  return { type: types.RESEND_CONFIRMATION_LINK_FAIL, payload };
}

export function sendWeeklyFollowUps(payload: IFollowUpSend) {
  return { type: types.SEND_FOLLOW_UP, payload };
}

export function sendWeeklyFollowUpsSuccess(payload: IFollowUpSend) {
  return { type: types.SEND_FOLLOW_UP_SUCCESS, payload };
}