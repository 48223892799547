import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Modal, Form, Select, Input, Button, Spin, InputNumber, DatePicker } from 'antd';

import { updateAgentsGroup, addAgentsGroup, getAgents } from '../../../store/agentsGroups/action';
import { agentsLoadingSelector, agentsSelector, agentsGroupErrorSelector } from '../../../store/agentsGroups/selectors';
import { UserDto } from '../../../types/user';
import { IObjectKeys } from '../../../helpers/globalTypes';
import { SubscriptionStatusEnum } from '../types';
import { US_DATE_FORMAT_WITH_TIME } from '../../../helpers/constants';

const { Option } = Select;
const AgentsGroupModal: React.FC<any> = ({ currentAgentsGroupData, visible, setVisible, modalType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const agents = useSelector(agentsSelector);
  const agentsLoading = useSelector(agentsLoadingSelector);
  const debounceLoadData = useCallback(debounce((size: number, searchValue: string) => dispatch(getAgents(size, searchValue)), 1000), []);
  const agentsGroupError = useSelector(agentsGroupErrorSelector);
  useEffect(() => {
    if (agentsGroupError && agentsGroupError.agents_group_name) {
      form.setFields([
        {
          name: 'agents_group_name',
          errors: [agentsGroupError.agents_group_name],
        },
      ]);
    }
  }, [agentsGroupError]);
  useEffect(() => {
    dispatch(getAgents(10, ''))
  }, [])
  useEffect(() => {
    if (currentAgentsGroupData && Object.keys(currentAgentsGroupData).length) {
      for (let fieldName in currentAgentsGroupData) {
        if (
          fieldName && currentAgentsGroupData.hasOwnProperty(fieldName) && fieldName !== 'agent_ids') {
          form.setFieldsValue({ [fieldName]: currentAgentsGroupData[fieldName] });
        } else if (fieldName &&
          currentAgentsGroupData.hasOwnProperty(fieldName) &&
          fieldName === 'agent_ids' &&
          currentAgentsGroupData.agentsOnGroup
        ) {
          const _agent_ids_to_form = currentAgentsGroupData['agentsOnGroup'].map((item: any) => {
            return {
              value: item.agent_id,
              label: item.name,
              key: item.agent_id,
            };
          });
          form.setFieldsValue({ ['agent_ids']: _agent_ids_to_form });
        }
      }
    } else {
      form.resetFields();
    }
  }, [form, currentAgentsGroupData]);

  const subscriptionStatusOptions = [
    { label: SubscriptionStatusEnum.trial, value: SubscriptionStatusEnum.trial},
    { label: SubscriptionStatusEnum.subscribed, value: SubscriptionStatusEnum.subscribed},
  ]

  const handleSubmit = (formData: any) => {
    if (currentAgentsGroupData._id) {
      const _agentsGroupData: IObjectKeys = {
        agent_ids: [],
      };
      Object.keys(formData).forEach(key => {
        if (key !== 'agent_ids') {
          _agentsGroupData[key] = formData[key];
        } else if (key === 'agent_ids') {
          const _agent_ids_arr = [];
          formData[key].forEach((item: any) => {
            const agentExistsOnGroup = currentAgentsGroupData.agent_ids.includes(item)
            if (! agentExistsOnGroup){
              _agentsGroupData['agent_ids'].push(item.value);
            }
          });
        }
      });
      dispatch(updateAgentsGroup({ ..._agentsGroupData, _id: currentAgentsGroupData._id }));
    } else {
      if (modalType === 'Agents Group') {
        const newGroup: object = {
          agents_group_name: formData.agents_group_name,
          description: formData.description,
          subscription_status: formData.subscription_status,
          seats: parseInt(formData.seats, 10),
          subscription_renewal_date: formData.subscription_renewal_date
        };
        if (formData.agent_ids && formData.agent_ids.length) {
          // @ts-ignore
          newGroup['agent_ids'] = formData.agent_ids.map((item: object) => item.value);
        } else {
          // @ts-ignore
          newGroup['agent_ids'] = [];
        }
        dispatch(addAgentsGroup({ ...newGroup }));
      }
    }
    dispatch(getAgents(10, ''))
  };
  return (
    <Modal
      title={(currentAgentsGroupData && currentAgentsGroupData._id ? 'Edit ' : 'Add ') + modalType}
      footer={null}
      visible={visible}
      width={320}
      onCancel={() => {
        form.resetFields();
        setVisible(false);
      }}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
      >
        <Form.Item label='Group Name' name='agents_group_name'
                   rules={[{ required: true, min: 4, max: 50, message: 'Required field, format - Group Name' }]}>
          <Input placeholder='Group Name'/>
        </Form.Item>
        <Form.Item label='Description' name='description'
                   rules={[{
                     required: true,
                     min: 4,
                     max: 100,
                     message: 'Required field, format - Group Description',
                   }]}>
          <Input placeholder='Description'/>
        </Form.Item>

        <Form.Item label='Subscription Status' name='subscription_status'
                   rules={[{ required: true, message: 'Required field, format - Subscription Status' }]}
        >
          <Select
            placeholder='Select Subscription Status'
            filterOption={false}
            options={subscriptionStatusOptions}
          />
        </Form.Item>

        <Form.Item label="Seats" name='seats'
                   rules={[{ required: true, message: 'Required field, format - Seats Number' }]}>
          <Input placeholder='Seats' type={'number'}/>
        </Form.Item>

        <Form.Item name='subscription_renewal_date'
                   rules={[{ required: true, message: 'Required field, format - Subscription Renewal Date' }]}
        >
          <DatePicker
            placeholder={`Set Subscription Renewal Date`}
            format={US_DATE_FORMAT_WITH_TIME}
            showTime
          />
        </Form.Item>

        {currentAgentsGroupData._id ? <Form.Item label='Agents' name='agent_ids'>
          <Select
            mode='multiple'
            labelInValue
            placeholder='Select agents'
            notFoundContent={agentsLoading ? <Spin size='small'/> : null}
            filterOption={false}
            options={agents.map((agent: UserDto) => {
              return ({ label: agent.name || agent.email, value: agent.agent_id })
            }
            )}
            onSearch={(value: string) => {
              debounceLoadData(10, value);
            }}
          />
        </Form.Item> : ''}
        <Form.Item>
          <Button type='primary'
                  htmlType='submit'>{(currentAgentsGroupData && currentAgentsGroupData._id ? 'Save' : 'Add')}</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AgentsGroupModal;
