import * as types from './types';

export function getAgentActivities(
  page: number = 1,
  size: number = 10,
  sortField: string = '',
  sortOrder: string = '',
  searchValue: string = '',
  agentIdOfAgent: string = ''
) {

  return {
    type: types.GET_AGENT_ACTIVITIES,
    payload: {
      page, size, sortField, sortOrder, searchValue, agentIdOfAgent
    },
  };
}

export function getAgentsActivitiesSuccess(activities: object) {
  return { type: types.GET_AGENT_ACTIVITIES_SUCCESS, payload: activities };
}

export function getAgentsActivitiesFail(error: object) {
  return { type: types.GET_AGENT_ACTIVITIES_FAIL, payload: error };
}

export function setCurrentAgentForActivities(payload: object) {
  return { type: types.SET_CURRENT_AGENT_FOR_ACTIVITIES, payload };
}


