export const GET_AGENT_GROUPS = 'GET_AGENT_GROUPS';
export const GET_AGENT_GROUPS_SUCCESS = 'GET_AGENT_GROUPS_SUCCESS';
export const GET_AGENT_GROUPS_FAIL = 'GET_AGENT_GROUPS_FAIL';

export const GET_AGENTS = 'GET_AGENTS';
export const GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS';
export const GET_AGENTS_FAIL = 'GET_AGENTS_FAIL';

export const UPDATE_AGENT_GROUP = 'UPDATE_AGENT_GROUP';
export const UPDATE_AGENT_GROUP_SUCCESS = 'UPDATE_AGENT_GROUP_SUCCESS';
export const UPDATE_AGENT_GROUP_FAIL = 'UPDATE_AGENT_GROUP_FAIL';

export const ADD_AGENT_GROUP = 'ADD_AGENT_GROUP';
export const ADD_AGENT_GROUP_SUCCESS = 'ADD_AGENT_GROUP_SUCCESS';
export const ADD_AGENT_GROUP_FAIL = 'ADD_AGENT_GROUP_FAIL';

export const SOFT_DELETE_AGENT_GROUP_SUCCESS = 'SOFT_DELETE_AGENT_GROUP_SUCCESS';
export const SOFT_DELETE_AGENT_GROUP_FAIL = 'SOFT_DELETE_AGENT_GROUP_FAIL';
export const SOFT_DELETE_AGENT_GROUP = 'SOFT_DELETE_AGENT_GROUP';

export const SET_AGENT_GROUP_TO_SUBSCRIPTION = "SET_AGENT_GROUP_TO_SUBSCRIPTION"
export const SET_AGENT_GROUP_TO_SUBSCRIPTION_SUCCESS = "SET_AGENT_GROUP_TO_SUBSCRIPTION_SUCCESS"
export const SET_AGENT_GROUP_TO_SUBSCRIPTION_FAIL = "SET_AGENT_GROUP_TO_SUBSCRIPTION_FAIL"

export const CANCEL_AGENT_GROUP_SUBSCRIPTION = "CANCEL_AGENT_GROUP_SUBSCRIPTION"
export const CANCEL_AGENT_GROUP_SUBSCRIPTION_SUCCESS = "CANCEL_AGENT_GROUP_SUBSCRIPTION_SUCCESS"
export const CANCEL_AGENT_GROUP_SUBSCRIPTION_FAIL = "CANCEL_AGENT_GROUP_SUBSCRIPTION_FAIL"

export const SET_STATUS_TO_FALSE = "SET_STATUS_TO_FALSE"
