export const GET_LISTINGS = 'GET_LISTINGS';
export const GET_LISTINGS_SUCCESS = 'GET_LISTINGS_SUCCESS';
export const GET_LISTINGS_FAIL = 'GET_LISTINGS_FAIL';

export const FORCE_DELETE_LISTING = 'FORCE_DELETE_LISTING';
export const FORCE_DELETE_LISTING_SUCCESS = 'FORCE_DELETE_LISTING_SUCCESS';
export const FORCE_DELETE_LISTING_FAIL = 'FORCE_DELETE_LISTING_FAIL';

export const TOGGLE_LISTING_PUBLISH_STATE = 'TOGGLE_LISTING_PUBLISH_STATE';
export const TOGGLE_LISTING_PUBLISH_STATE_SUCCESS = 'TOGGLE_LISTING_PUBLISH_STATE_SUCCESS';
export const TOGGLE_LISTING_PUBLISH_STATE_FAIL = 'TOGGLE_LISTING_PUBLISH_STATE_FAIL';
