import * as types from './types';

const initialState: any = {
  agentsActivities: [],
  agentsActivitiesLoading: false,
  agentsActivitiesError: null,
  total: 0,
  currentAgentForActivities: null,
  agentData: null

};

export default function agentActivitiesReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {

    case types.GET_AGENT_ACTIVITIES:
      return {
        ...state,
        agentsActivities: [],
        agentsActivitiesLoading: true,
        agentsActivitiesError: null,
        currentAgent: null
      };

    case types.GET_AGENT_ACTIVITIES_SUCCESS:
      return {
        ...state,
        agentsActivities: action.payload.data,
        total: action.payload.total,
        agentsActivitiesLoading: false,
        agentData: action.payload.agentData
      };

      case types.GET_AGENT_ACTIVITIES_FAIL:
      return {
        ...state,
        agentsActivitiesError: action.payload,
        total: 0,
        agentsActivitiesLoading: false,
      };

      case types.SET_CURRENT_AGENT_FOR_ACTIVITIES:
      return {
        ...state,
        currentAgentForActivities: action.payload,
      };

    default:
      return state;
  }
}
