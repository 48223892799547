import React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/user/selectors';

const Dashboard = () => {
  const user = useSelector(userSelector);
  return (
    <div>
      <h1 className='mt-6'>Dashboard</h1>
      <div><h1>Hello </h1> {user.email}</div>
    </div>
  );
};
export default Dashboard;
