import { RootStateOrAny } from 'react-redux';

export const usersSelector = (state: RootStateOrAny) => state.usersReducer.users;
export const usersErrorSelector = (state: RootStateOrAny) => state.usersReducer.usersError;
export const userSaveSuccessSelector = (state: RootStateOrAny) => state.usersReducer.userSaveSuccess;
export const softDeleteUserSuccessSelector = (state: RootStateOrAny) => state.usersReducer.softDeleteUserSuccess;
export const userUpdateSuccessSelector = (state: RootStateOrAny) => state.usersReducer.userUpdateSuccess;
export const usersLoadingSelector = (state: RootStateOrAny) => state.usersReducer.usersLoading;
export const usersTotalSelector = (state: RootStateOrAny) => state.usersReducer.total;
export const forceDeleteUserSuccessSelector = (state: RootStateOrAny) => state.usersReducer.forceDeleteUserSuccess;
