import React, { useEffect, useState } from 'react';
import UsersTable from '../../components/Users/UsersTable';
import { UserModalFormData } from '../../components/Users/types';
import { useSelector } from 'react-redux';
import { userSaveSuccessSelector, userUpdateSuccessSelector } from '../../store/users/selectors';
import Index from '../../components/Users/UserModal';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const Users = () => {
  const userSaveSuccess = useSelector(userSaveSuccessSelector);
  const userUpdateSuccess = useSelector(userUpdateSuccessSelector);
  const [currentUser, setCurrentUser] = useState<UserModalFormData>({});
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (userSaveSuccess || userUpdateSuccess) {
      setVisible(false);
      setCurrentUser({});
      // dispatch(getUsers(paginationOptions.page, paginationOptions.pageSize, sortOptions.sortField, sortOptions.sortOrder));
    }
  }, [userSaveSuccess, userUpdateSuccess]);
  return (
    <div>
      <div className='mb-5 page-header-wrapper'>
        <h1 className='font-24'>Users</h1>
        <div>
          <Button
            type='primary'
            size='large'
            onClick={() => {
              setCurrentUser({});
              setVisible(true);
            }}
          >
            <PlusOutlined className='font-24' />
          </Button>
        </div>
      </div>

      <UsersTable
        setCurrentUser={(userData) => setCurrentUser(userData)}
        setModalVisible={(visibleState) => setVisible(visibleState)}
      />
      <Index
        visible={visible}
        userData={currentUser}
        setVisible={setVisible}
        modalType='user'
      />
    </div>
  );
};
export default Users;
