import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import AgentsApi from '../../api/Agents';

import {
  ADD_AGENT, DELETE_AGENT, FORCE_DELETE_AGENT,
  GET_AGENT,
  GET_ALL_AGENTS,
  GET_GROUPS_OF_AGENT, RESEND_CONFIRMATION_LINK, SEND_FOLLOW_UP, UPDATE_AGENT,
} from './types';
import {
  getGroupsOfAgentSuccess,
  getGroupsOfAgentFail,
  getAgentsSuccess,
  getAgentsFail,
  addAgentSuccess,
  addAgentFail,
  updateAgentSuccess,
  updateAgentFail, forceDeleteAgentSuccess, forceDeleteAgentFail, resendConfirmationLinkSuccess, sendWeeklyFollowUpsSuccess, getAllAgentsSuccess, deleteAgentSuccess, deleteAgentFail,
} from './action';
import { generateErrorMessage, openNotification } from '../../helpers/globalFunctions';
import Auth from '../../api/Auth';
import { IObjectKeys } from 'helpers/globalTypes';
import { IFollowUpSend } from 'components/Users/types';
import { IAllAgents } from 'types/user';


function* getGroupsOfAgentSaga({ payload }: any) {
  try {
    const response = yield call(
      AgentsApi.getGroupsOfAgent, payload,
    );
    yield put(getGroupsOfAgentSuccess(response));
  } catch (e) {
    yield put(getGroupsOfAgentFail(e));
  }
}

function* getAgentsSaga({ payload }: any) {
  try {
    const response = yield call(
      AgentsApi.getAgents, { ...payload, sortOrder: payload.sortOrder, sortField: payload.sortField },
    );
    yield put(getAgentsSuccess(response));
  } catch (e) {
    yield put(getAgentsFail(e));
  }
}

function* getAllAgentsSaga() {
  try {
    const response: IAllAgents = yield call(AgentsApi.getAllAgents, {});
    yield put(getAllAgentsSuccess(response));
  } catch (e) {
    yield put(getAgentsFail(e));
  }
}

function* addAgentSaga({ payload }: any) {
  try {
    const response = yield call(
      AgentsApi.addAgent, payload,
    );
    yield put(addAgentSuccess(response));
  } catch (e) {
    openNotification('error', generateErrorMessage(e));
    yield put(addAgentFail(e));
  }
}

function* updateAgentSaga({ payload }: any) {
  try {
    const response = yield call(
      AgentsApi.updateAgent, { params: { _id: payload._id }, data: { ...payload } },
    );
    yield put(updateAgentSuccess(response));
  } catch (e) {
    yield put(updateAgentFail(e));
  }
}

function* forceDeleteAgentSaga({ payload }: any) {
  try {
    console.log({payload})
    const response = yield call(
      AgentsApi.forceDeleteAgent, payload,
    );
    yield put(forceDeleteAgentSuccess(response));
    openNotification('success', 'Agent deleted successfully');
  } catch (e) {
    openNotification('error', generateErrorMessage(e));
    yield put(forceDeleteAgentFail(e));
  }
}

function* deleteAgentSaga({ payload }: IObjectKeys) {
  try {
    const response: IObjectKeys = yield call(AgentsApi.deleteAgent, payload);
    yield put(deleteAgentSuccess(response));
    openNotification('success', 'Agent deleted successfully');
  } catch (e) {
    openNotification('error', generateErrorMessage(e));
    yield put(deleteAgentFail(e));
  }
};

function* resendConfirmationLinkSaga({ payload }: any) {
  try {
    const response = yield call(
      Auth.resendConfirmationLink, payload,
    );
    yield put(resendConfirmationLinkSuccess(response));
    openNotification('success', 'Email has been sent successfully.');
  } catch (e) {
    openNotification('error', generateErrorMessage(e));
    yield put(resendConfirmationLinkSuccess(e));
  }
}

function* sendWeeklyFollowUpSaga({ payload }: IObjectKeys) {
  try {
    const response: IFollowUpSend = yield call(AgentsApi.sendFollowUps, payload);
    yield put(sendWeeklyFollowUpsSuccess(response));
     openNotification('success', 'Email has been sent successfully.');
  } catch (e) {
    const error = e?.body?.errors[0]?.message || e;
    openNotification('error', generateErrorMessage(error));
    yield put(getAgentsFail(e));
  }
}

function* watchGetAgents() {
  yield takeLatest(GET_AGENT, getAgentsSaga);
}

function* watchGetAllAgents() {
  yield takeLatest(GET_ALL_AGENTS, getAllAgentsSaga);
}

function* watchAddAgent() {
  yield takeLatest(ADD_AGENT, addAgentSaga);
}

function* watchUpdateAgent() {
  yield takeLatest(UPDATE_AGENT, updateAgentSaga);
}

function* watchForceDeleteAgent() {
  yield takeLatest(FORCE_DELETE_AGENT, forceDeleteAgentSaga);
}

function* watchDeleteAgent() {
  yield takeLatest(DELETE_AGENT, deleteAgentSaga);
}

function* watchGetGroupsOfAgentSaga() {
  yield takeLatest(GET_GROUPS_OF_AGENT, getGroupsOfAgentSaga);
}

function* watchResendConfirmationLinkSaga() {
  yield takeLatest(RESEND_CONFIRMATION_LINK, resendConfirmationLinkSaga);
}

function* watchSendWeeklyFollowUpSaga() {
  yield takeLatest(SEND_FOLLOW_UP, sendWeeklyFollowUpSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetGroupsOfAgentSaga),
    fork(watchGetAgents),
    fork(watchGetAllAgents),
    fork(watchAddAgent),
    fork(watchForceDeleteAgent),
    fork(watchDeleteAgent),
    fork(watchUpdateAgent),
    fork(watchResendConfirmationLinkSaga),
    fork(watchSendWeeklyFollowUpSaga),
  ]);
}
