import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Input, Table } from 'antd';
import { fieldDto, IObjectKeys } from 'helpers/globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  forceDeleteUserSuccessSelector,
  usersLoadingSelector,
} from 'store/users/selectors';

import {
  agentsActivitiesSelector,
  agentsActivitiesTotalSelector,
} from 'store/agentActivities/selectors';
import { getUsers } from 'store/users/action';
import { getAgentActivities } from 'store/agentActivities/action';
import { TablePaginationConfig } from 'antd/lib/table/interface';

import '../styles.scss';
import { useParams } from 'react-router-dom';
import {
  httpMethodNameTo,
  responseToEntityNameUi,
} from '../../../helpers/globalFunctions';
import moment from 'moment';

interface UsersTableInterFace {
  setActivitiesData: Dispatch<SetStateAction<any>>;
  setVisible: Dispatch<SetStateAction<any>>;
}

const AgentActivities: React.FC<UsersTableInterFace> = ({
  setActivitiesData,
  setVisible,
}) => {
  const { agentIdOfAgent } = useParams();
  const dispatch = useDispatch();
  const agentsActivities = useSelector(agentsActivitiesSelector);
  const loading = useSelector(usersLoadingSelector);
  const total = useSelector(agentsActivitiesTotalSelector);
  const forceDeleteUserSuccess = useSelector(forceDeleteUserSuccessSelector);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [sortOptions, setSortOptions] = useState({
    sortOrder: 'desc',
    sortField: 'updated_at',
    searchValue: '',
  });

  const delayGetAgentActivities = debounce((...rest) => {
    dispatch(getAgentActivities(...rest));
  }, 200);

  const entity = (_id: fieldDto, rowData: IObjectKeys) => {
    if (
      rowData?.entity?.includes('not') ||
      rowData?.entity?.includes('follow')
    ) {
      return (
        <>
          <div>{rowData?.body?.title}</div>
          <div>
            {rowData?.response_data?.listingOfNote
              ? `Listing: ${rowData?.response_data?.listingOfNote?.addr}`
              : ''}
            {rowData?.response_data?.landlordOfNote
              ? `Landlord: ${rowData?.landlordOfNote?.response_data?.name} ${rowData?.response_data?.landlordOfNote?.email} ${rowData?.response_data?.landlordOfNote?.phone}`
              : ''}
          </div>
        </>
      );
    }

    if (rowData?.entity?.includes('listing')) {
      return (
        <>
          <div>Address: {rowData?.response_data?.addr}</div>
          <div>
            Landlord: {rowData?.response_data?.landlordOfListing?.name}{' '}
            {rowData?.response_data?.landlordOfListing?.email}{' '}
            {rowData?.response_data?.landlordOfListing?.phone}
          </div>
        </>
      );
    }

    if (rowData?.entity?.includes('user')) {
      return (
        <>
          <div>
            Landlord: {rowData?.response_data?.name}{' '}
            {rowData?.response_data?.email} {rowData?.response_data?.phone}
          </div>
        </>
      );
    }
  };

  const columns = [
    {
      dataIndex: '_id',
      key: '_id',
      title: 'Entity',
      sorter: false,
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return entity(_id, rowData);
      },
    },
    {
      dataIndex: '_id',
      key: '_id',
      title: 'Activity',
      sorter: false,
      render: (_id: fieldDto, rowData: IObjectKeys) => {
        return (
          <span>
            {httpMethodNameTo(rowData.method)} -{' '}
            {responseToEntityNameUi(rowData.original_url)}
          </span>
        );
      },
    },
    {
      dataIndex: 'created_at',
      key: '_v',
      title: 'Date',
      sorter: true,
      render: (created_at: Date) => {
        return moment(created_at).format('MM-DD-YYYY hh:mm A');
      },
    },
  ];

  useEffect(() => {
    delayGetAgentActivities(
      pagination.current,
      pagination.pageSize,
      sortOptions.sortField,
      sortOptions.sortOrder,
      sortOptions.searchValue,
      agentIdOfAgent,
    );
  }, [
    dispatch,
    pagination,
    sortOptions.sortField,
    sortOptions.sortOrder,
    sortOptions.searchValue,
  ]);

  useEffect(() => {
    if (forceDeleteUserSuccess) {
      dispatch(
        getUsers(
          pagination.current,
          pagination.pageSize,
          sortOptions.sortField,
          sortOptions.sortOrder,
          sortOptions.searchValue,
          agentIdOfAgent,
        ),
      );
    }
  }, [forceDeleteUserSuccess]);

  const handleTableChange = (
    paginationOpt: TablePaginationConfig,
    _: any,
    sorter: any,
  ): void => {
    setPagination({
      current: paginationOpt.current,
      pageSize: paginationOpt.pageSize,
    });
    setSortOptions((prevState) => ({
      ...prevState,
      sortField: sorter.field,
      sortOrder: sorter.order,
    }));
  };

  return (
    <div>
      <Table
        columns={columns}
        dataSource={agentsActivities}
        rowKey={(record) => record._id}
        onChange={handleTableChange}
        pagination={{ ...pagination, total }}
        loading={loading}
      />
    </div>
  );
};
export default AgentActivities;
