import React, { useEffect, useState } from 'react';
import AgentActivitiesTable from '../../components/AgentActivities/AgentActivitiesTable';
import { useSelector } from 'react-redux';
import { currentAgentDataSelector} from '../../store/agentActivities/selectors';
import  AgentActivitiesModal from '../../components/AgentActivities/AgentActivitiesModal';

const AgentActivities = () => {
  const  currentAgentData = useSelector( currentAgentDataSelector);

const [activitiesData, setActivitiesData] = useState({
  requestData: null,
  responseData: null,
  modalType: ''
});
const [visible, setVisible] = useState(false);
  return (
    <div>
      <div className='mb-5 page-header-wrapper'>
        <h1 className='font-24'>
          Agent {currentAgentData?.name} {currentAgentData?.email}
        </h1>
      </div>

      <AgentActivitiesTable
        setActivitiesData={setActivitiesData}
        setVisible={setVisible}
      />
      <AgentActivitiesModal
        setVisible={(state) => setVisible(state)}
        visible={visible}
        modalType={activitiesData.modalType}
        activitiesData={activitiesData}
      />
    </div>
  );
};
export default AgentActivities;
