import * as React from 'react';
import { Button, Tooltip } from 'antd';
import { EditOutlined, DeleteOutlined, MailOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { resendConfirmationLink } from '../../../store/agents/action';

const ActionsCell = ({ rowData, openEditModal, openDeleteConfirmModal }: any) => {
  const dispatch = useDispatch();
  return (
    <div className='flex-centered'>
      <Tooltip title='resend confirmation link'>
        <Button
          key='resendButton'
          className='flex-centered recover-icon-color mr-25'
          onClick={() => {
            dispatch(resendConfirmationLink(rowData.agent_id));
          }}
        >
          <MailOutlined className='font-24'/>
        </Button>
      </Tooltip>
      <Button
        key='deleteButton'
        className='flex-centered delete-icon-color mr-25'
        onClick={() => {
          openDeleteConfirmModal({
            _id: rowData._id,
            email: rowData.email,
            name: rowData.name
          });
        }}
      >
        <DeleteOutlined className='font-24'/>
      </Button>
      <Button
        key='editButton'
        className='flex-centered edit-icon-color mr-25'
        onClick={() => openEditModal && openEditModal({
          ...rowData,
        })}
      >
        <EditOutlined className='font-24'/>
      </Button>
    </div>
  );
};

export default ActionsCell;
