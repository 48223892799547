import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import AgentsGroupsApi from '../../api/AgentsGroups';

import {
  ADD_AGENT_GROUP,
  GET_AGENT_GROUPS,
  GET_AGENTS,
  UPDATE_AGENT_GROUP,
  SOFT_DELETE_AGENT_GROUP, SET_AGENT_GROUP_TO_SUBSCRIPTION, CANCEL_AGENT_GROUP_SUBSCRIPTION
} from './types';
import {
  getAgentsGroupsSuccess,
  getAgentsGroupsFail,
  updateAgentsGroupSuccess,
  updateAgentsGroupFail,
  addAgentsGroupSuccess,
  addAgentsGroupFail,
  getAgentsSuccess,
  getAgentsFail,
  softDeleteAgentsGroupSuccess,
  softDeleteAgentsGroupFail,
  setAgentGroupSubscriptionSuccess,
  setAgentGroupSubscriptionFail,
  cancelAgentGroupSubscriptionSuccess, cancelAgentGroupSubscriptionFail
} from './action';
import {generateErrorMessage, openNotification} from "../../helpers/globalFunctions";

function* getAgentsGroupsSaga({ payload }: any) {
  try {
    const response = yield call(
      AgentsGroupsApi.getAgentsGroups, { ...payload, sortOrder: payload.sortOrder, sortField: payload.sortField },
    );

    yield put(getAgentsGroupsSuccess(response));
  } catch (e) {
    yield put(getAgentsGroupsFail(e));
  }
}

function* addAgentsGroupSaga({ payload }: any ) {
  try {
    const response = yield call(
      AgentsGroupsApi.addAgentsGroup, payload,
    );
    yield put(addAgentsGroupSuccess(response));
  } catch (e) {
    yield put(addAgentsGroupFail(e));
  }
}

function* updateAgentsGroupSaga({ payload }: any ) {
  try {
    const response = yield call(
      AgentsGroupsApi.updateAgentsGroup, { params: { _id: payload._id }, data: { ...payload } },
    );
    yield put(updateAgentsGroupSuccess(response));
  } catch (e) {
    yield put(updateAgentsGroupFail(e));
  }
}

function* getAgentsSaga({ payload }: any) {
  try {
    const response = yield call(
      AgentsGroupsApi.getAgents, payload,
    );

    yield put(getAgentsSuccess(response));
  } catch (e) {
    yield put(getAgentsFail(e));
  }
}

function* softDeleteAgentsGroupSaga({ payload }: any) {
  try {
    const response = yield call(
      AgentsGroupsApi.softDeleteAgentsGroup, payload,
    );
    yield put(softDeleteAgentsGroupSuccess(response));
  } catch (e) {
    yield put(softDeleteAgentsGroupFail(e));
  }
}

function* setAgentSubscriptionSaga({ payload }: any) {
  try {
    const response = yield call(
        AgentsGroupsApi.updateSubscriptionStatus, payload,
    );
    yield put(setAgentGroupSubscriptionSuccess(response));
    openNotification('success', generateErrorMessage("Updated To Premium"));
  } catch (e) {
    openNotification('error', generateErrorMessage(e));
    yield put(setAgentGroupSubscriptionFail(e));
  }
}

function* cancelAgentSubscriptionSaga({ payload }: any) {
  try {
    const response = yield call(
        AgentsGroupsApi.removePremiumSubscription, payload,
    );
    yield put(cancelAgentGroupSubscriptionSuccess(response));
    openNotification('success', generateErrorMessage("Updated To Free"));
  } catch (e) {
    yield put(cancelAgentGroupSubscriptionFail(e));
  }
}
function* watchSoftDeleteAgentsGroupSaga() {
  yield takeLatest(SOFT_DELETE_AGENT_GROUP, softDeleteAgentsGroupSaga);
}

function* watchSetAgentSubscriptionSaga() {
  yield takeLatest(SET_AGENT_GROUP_TO_SUBSCRIPTION, setAgentSubscriptionSaga);
}

function* watchCancelAgentSubscriptionSaga() {
  yield takeLatest(CANCEL_AGENT_GROUP_SUBSCRIPTION, cancelAgentSubscriptionSaga);
}

function* watchAgentsGroups() {
  yield takeLatest(GET_AGENT_GROUPS, getAgentsGroupsSaga);
}

function* watchAddAgentsGroup() {
  yield takeLatest(ADD_AGENT_GROUP, addAgentsGroupSaga);
}

function* watchUpdateAgentsGroup() {
  yield takeLatest(UPDATE_AGENT_GROUP, updateAgentsGroupSaga);
}

function* watchGetAgentsSaga() {
  yield takeLatest(GET_AGENTS, getAgentsSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchAgentsGroups),
    fork(watchUpdateAgentsGroup),
    fork(watchAddAgentsGroup),
    fork(watchGetAgentsSaga),
    fork(watchSoftDeleteAgentsGroupSaga),
    fork(watchSetAgentSubscriptionSaga),
    fork(watchCancelAgentSubscriptionSaga),
  ]);
}
