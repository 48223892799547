import * as types from './types';
import { UserDto } from '../../types/user';

const initialState: any = {
  users: [],
  usersLoading: false,
  usersError: null,
  userSaveSuccess: false,
  userUpdateSuccess: false,
  softDeleteUserSuccess: false,
  forceDeleteUserSuccess: false,
  total: 0,
};

export default function usersReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.ADD_USER:
    case types.UPDATE_USER:
      return {
        ...state,
        userUpdateSuccess: false,
        userSaveSuccess: false,
        usersError: null,
        usersLoading: true,
      };
    case types.ADD_USER_SUCCESS:
      const usersCopy = [action.payload[0],...state.users];
      // usersCopy.length = usersCopy.length - 1;
      // usersCopy.unshift(action.payload[0]);
      return {
        ...state,
        users: usersCopy,
        userSaveSuccess: true,
        usersLoading: false,
      };
    case types.UPDATE_USER_SUCCESS:
      const users = state.users.map((user: UserDto) =>  {
        if (user._id === action.payload[0]?._id) {
          return action.payload[0];
        }
        return user;
      });
      return {
        ...state,
        users,
        userUpdateSuccess: true,
        usersError: null,
        usersLoading: false,
      };
    case types.ADD_USER_FAIL:
    case types.UPDATE_USER_FAIL:
      return {
        ...state,
        userUpdateSuccess: false,
        usersError: action.payload,
        usersLoading: false,
      };
    case types.GET_USERS:
      return {
        ...state,
        usersLoading: true,
        usersError: null,
        forceDeleteUserSuccess: false
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: [...action.payload.data],
        total: action.payload.metadata[0].total,
        usersLoading: false,
      };
    case types.GET_USERS_FAIL:
      return {
        ...state,
        usersError: action.payload,
        total: 0,
        usersLoading: false,
      };
    case types.SOFT_DELETE_USER:
      return {
        ...state,
        usersError: null,
        usersLoading: true,
        softDeleteUserSuccess: false
      };
    case types.SOFT_DELETE_USER_SUCCESS:
      const user = state.users.find((item: UserDto) => item._id === action.payload._id);
      if (user) user.is_active = false;
      return {
        ...state,
        usersLoading: false,
        softDeleteUserSuccess: true
      };
    case types.SOFT_DELETE_USER_FAIL:
      return {
        ...state,
        usersError: action.payload,
        usersLoading: false,
        softDeleteUserSuccess: false
      };

    case types.FORCE_DELETE_USER:
      return {
        ...state,
        usersError: null,
        usersLoading: true,
        forceDeleteUserSuccess: false
      };

    case types.FORCE_DELETE_USER_SUCCESS:
      return {
        ...state,
        usersLoading: false,
        forceDeleteUserSuccess: true
      };

    case types.FORCE_DELETE_USER_FAIL:
      return {
        ...state,
        usersError: action.payload,
        usersLoading: false,
        forceDeleteUserSuccess: false
      };

    case types.RECOVER_USER:
      return {
        ...state,
        usersError: null,
        usersLoading: true,
      };
    case types.RECOVER_USER_SUCCESS:
      const revokedUser = state.users.find((item: UserDto) => item._id === action.payload);
      if (revokedUser) revokedUser.is_active = true;
      return {
        ...state,
        usersError: null,
        usersLoading: false,
      };
    case types.RECOVER_USER_FAIL:
      return {
        ...state,
        usersError: action.payload,
        usersLoading: false,
      };
    default:
      return state;
  }
}
