import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Table, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { TablePaginationConfig } from 'antd/lib/table/interface';

import '../styles.scss';

import {
  listingDeleteSuccessSelector,
  listingsLoadingSelector,
  listingsSelector,
  listingsTotalSelector,
  listingPublishStateLoadingSelector
} from 'store/listings/selectors';
import { forceDeleteListing, getListings, toggleListingPublishState } from 'store/listings/action';
import { fieldDto, IObjectKeys } from 'helpers/globalTypes';
import { UserDto } from '../../../types/user';
import ActionButtons from '../ActionButtons';
import Total from 'components/Shared/Total';

const ListingTable: React.FC = () => {
  const dispatch = useDispatch();
  const listings = useSelector(listingsSelector);
  const loading = useSelector(listingsLoadingSelector);
  const total = useSelector(listingsTotalSelector);
  const listingDeleteSuccess = useSelector(listingDeleteSuccessSelector);
  const listingPublishStateLoading = useSelector(listingPublishStateLoadingSelector);

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [sortOptions, setSortOptions] = useState({
    sortOrder: 'desc',
    sortField: 'updated_at',
    searchValue: ''
  });

  function confirm(listingData: IObjectKeys) {
    Modal.confirm({
      title: 'Confirm Listing deletion!',
      icon: <ExclamationCircleOutlined />,
      content: `Do you really want to delete listing ${listingData?.addr} `,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        dispatch(forceDeleteListing(listingData._id));
      },
    });
  }

  function togglePublishListingState(listingId: string, action: string) {
    dispatch(toggleListingPublishState({ listingId, action }));
  }

  const columns = [
    {
      dataIndex: 'addr',
      key: 'addr',
      title: 'Address',
      sorter: true,
      render: (addr: string) => {
        return (
          <div className='flex-left listing-address'>
            <Link to={`/listing/${addr}`}>{addr}</Link>
          </div>
        );
      },
    },
    { dataIndex: 'sub_area', key: 'sub_area', title: 'Sub Area', sorter: true },
    {
      dataIndex: 'agentOfList',
      key: 'agentOfList',
      title: 'Agent',
      sorter: true,
      render: (agentOfList: IObjectKeys) => {
        return (
          <div className='flex-left listing-address'>
            {agentOfList && agentOfList['agent_id'] && (
              <Link to={`/agent/${agentOfList['agent_id']}`}>
                {agentOfList['name']}
              </Link>
            )}
          </div>
        );
      },
    },

    {
      dataIndex: 'created_at',
      key: 'created_at',
      title: 'Added At',
      render: (created_at: Date) => {
        const date = new Date(created_at);
        const day = date.getDay(),
          month = date.getMonth() + 1,
          year = date.getFullYear();
        return `${day}-${month}-${year}`;
      },
    },
    {
      dataIndex: '_id',
      key: '_id',
      title: 'Actions',
      render: (_id: fieldDto, rowData: UserDto) => {
        return (
          <div>
            <ActionButtons
              rowData={rowData}
              openDeleteConfirmModal={(listingData: IObjectKeys) => {
                confirm(listingData);
              }}
              listingPublishStateLoading={rowData._id === listingPublishStateLoading}
              onTogglePublishState={togglePublishListingState}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(
      getListings(
        pagination.current,
        pagination.pageSize,
        sortOptions.sortField,
        sortOptions.sortOrder,
        sortOptions.searchValue
      ),
    );
  }, [dispatch, pagination, sortOptions.sortField, sortOptions.sortOrder, sortOptions.searchValue]);

  useEffect(() => {
    if (!listingPublishStateLoading) {
      dispatch(
        getListings(
          pagination.current,
          pagination.pageSize,
          sortOptions.sortField,
          sortOptions.sortOrder,
          sortOptions.searchValue
        ),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingPublishStateLoading]);

  useEffect(() => {
    if (listingDeleteSuccess) {
      dispatch(
        getListings(
          pagination.current,
          pagination.pageSize,
          sortOptions.sortField,
          sortOptions.sortOrder,
          sortOptions.searchValue,
        ),
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingDeleteSuccess]);

  const handleTableChange = (
    paginationOpt: TablePaginationConfig,
    _: any,
    sorter: any,
  ): void => {
    setPagination({
      current: paginationOpt.current,
      pageSize: paginationOpt.pageSize,
    });
    setSortOptions({
      ...sortOptions,
      sortField: sorter.field,
      sortOrder: sorter.order
    });
  };

  return (
    <div>
      <Total total={total} />
      <Input
        placeholder='Find a specific listing by typing its address information.'
        onChange={(e) => { setSortOptions({ ...sortOptions, searchValue: e.currentTarget.value }); }}
        value={sortOptions.searchValue}
        allowClear
      />
      <Table
        columns={columns}
        dataSource={listings}
        rowClassName={(record) => {
          // @ts-ignore
          if (record.is_active && record.is_active === false) {
            return 'deactivated-row';
          } else {
            return '';
          }
        }}
        rowKey={(record: IObjectKeys) => record._id}
        onChange={handleTableChange}
        pagination={{ ...pagination, total }}
        loading={loading}
      />
    </div>
  );
};
export default ListingTable;
