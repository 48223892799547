import Api from './api';
import { AxiosPromise } from 'axios';
import { IObjectKeys } from '../helpers/globalTypes';
import { AuthError } from '../types/user';

export default class AgentsApi {
  static getListings(params: any): any {
    const api = new Api('listing');
    return api.get('', { params });
  }

  static forceDeleteListing(listingId: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api('listing/force_delete_listing');
    return api.destroy(listingId);
  }

  static togglePublishListingState(params: { listingId: any, action: string }): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api(`listing/${params.action}`);
    return api.put(params.listingId, { data: params });
  }
}
