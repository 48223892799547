import React from 'react';
import AuthForm from 'components/Auth/authForm/AuthForm';
import { useDispatch } from 'react-redux';
import { signIn } from '../../store/user/action';
import { AuthFormInterface } from '../../components/Auth/authForm/authTypes';

const SignIn: React.FC = () => {
  const dispatch = useDispatch();
  const onSubmit = async (data: AuthFormInterface) => {
    dispatch(signIn(data));
  };

  return (
    <div className='auth-form-wrapper'>
      <AuthForm
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default SignIn;
