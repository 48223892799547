import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification';
import { ReactNode } from 'react';
import { IObjectKeys } from './globalTypes';

export const configureNotifications = (placement: NotificationPlacement, duration = 3, margin = { top: 90 }) => {
  return notification.config({
    placement,
    ...margin,
    duration,
    rtl: false,
  });
};

export const capitalizeFirstLetter = (s: string) => {
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const beautifiedKeyStyling = (str: string) => {
  return str.split('_').map(item => capitalizeFirstLetter(item)).join(' ');
};

export const openNotification = (type: string = 'success', message: string | ReactNode, description?: string | ReactNode) => {
  const options = {
    message,
    description,
  };
  if (type === 'success') {
    return notification.success(options);
  } else {
    return notification.error(options);
  }
};

export const generateErrorMessage = (message: string | IObjectKeys) => {
  if (typeof message === 'string') return message;
  return Object.keys(message).map(key => {
    return `${beautifiedKeyStyling(key)} :: ${message[key]}`;
  }).join(', ');
};

export const sanitizePhoneNumber = (str: string) => str.replace(/[^0-9]/g, '');

export const httpMethodNameTo = (str: string) => {

  switch (str) {
    case 'POST':
      return 'Create'

    case 'PUT':
      return 'Edit'

    case 'DELETE':
      return 'Delete'

    default:
      return ''
  }
}


export const responseToEntityNameUi = (original_url: string) => {

  if (original_url.includes('notification') || original_url.includes('note') ) {
    return 'Follow Up or Note'
  }

 if (original_url.includes('landlord')) {
   return 'Landlord'
 }
 if (original_url.includes('listing')) {
   return 'Listing'
 }

 if (original_url.includes('landlord')) {
   return 'Landlord'
 }

 if (original_url.includes('users')) {
   return 'Users'
 }

}

export const getBrowser = () => {
  if (navigator.userAgent.indexOf("Chrome") != -1) {
    return "YYYY-MM-DD HH:mm:ss";
  } else if (navigator.userAgent.indexOf("Opera") != -1) {
    return "YYYY-MM-DD HH:mm:ss";
  } else if (navigator.userAgent.indexOf("MSIE") != -1) {
    return "YYYY-MM-DD HH:mm:ss";
  } else if (navigator.userAgent.indexOf("Firefox") != -1) {
    return "YYYY-MM-DD HH:mm:ss";
  } else if (navigator.userAgent.indexOf("Safari")) {
    return "YYYY/MM/DD HH:mm:ss";
  } else {
    return "unknown";
  }
}
