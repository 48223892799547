import React, { useState } from 'react';
import './SubscriptionStatus.scss';
import SubscriptionModal from './subscription-modal/SubscriptionModal';
import {IObjectKeys} from "../../../helpers/globalTypes";
interface ISubscriptionStatus {
  agent: IObjectKeys;
}
const SubscriptionStatus = ({ agent }: ISubscriptionStatus) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModalHandler = () => {
    setIsModalVisible(true);
  };

  const closeModalHandler = () => {
    setIsModalVisible(false);
  };

  return (
    <div className={'subscription-status'}>
      <button onClick={openModalHandler}>{agent?.subscription_level}</button>
      <SubscriptionModal
        closeModalHandler={closeModalHandler}
        isModalVisible={isModalVisible}
        agent={agent}
      />
    </div>
  );
};

export default SubscriptionStatus;
