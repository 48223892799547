import * as React from 'react';
import { Modal, Button } from 'antd';
import { DeleteOutlined, RollbackOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { softDeleteAgentsGroup } from '../../../store/agentsGroups/action';
import moment from 'moment';

const { confirm } = Modal;
const ActionsCell = ({ data, openEditModal }: any) => {
  const dispatch = useDispatch();
  const openDeleteConfirm = () => {
    confirm({
      title: 'Are You Sure you want to delete this Group?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(softDeleteAgentsGroup(data._id));
      },
    });
  };

  const openRecoverConfirm = () => {
    confirm({
      title: 'Do you want to recover this Group?',
      icon: <RollbackOutlined />,
      onOk() {
        // recoverAccount(data._id, true);
      },
    });
  };

  const buttons: any = [];
  if (!data.is_active) {
    buttons.unshift(
      <Button
        key='rollbackButton'
        className='flex-centered mr-25 recover-icon-color'
        onClick={() => openRecoverConfirm()}
      >
        <RollbackOutlined className='font-24' />
      </Button>,
    );
  } else {
    buttons.unshift(
      <Button
        key='editButton'
        className='flex-centered edit-icon-color mr-25'
        onClick={() => openEditModal && openEditModal({
          _id: data._id,
          agents_group_name: data.agents_group_name,
          description: data.description,
          agent_ids: data.agent_ids,
          agentsOnGroup: data.agentsOnGroup,
          subscription_status: data.subscription_status,
          seats: data.seats,
          subscription_renewal_date: data.subscription_renewal_date ? moment(data.subscription_renewal_date) : null
        })}
      >
        <EditOutlined className='font-24' />
      </Button>,
      <Button
        key='deleteButton'
        className='flex-centered delete-icon-color'
        onClick={() => openDeleteConfirm()}
      >
        <DeleteOutlined className='font-24' />
      </Button>
    );
  }
  return (
    <div className='flex-centered'>
      {buttons.map((b: React.ReactNode) => b)}
    </div>
  );
};

export default ActionsCell;
