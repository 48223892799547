import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Input, Modal, Table } from 'antd';
import { fieldDto, IObjectKeys } from 'helpers/globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import {
  agentDeleteSuccessSelector,
  agentsLoadingSelector,
  agentsSelector,
  agentsTotalSelector,
} from 'store/agents/selectors';

import { deleteAgent, getAgents } from 'store/agents/action';
import { setCurrentAgentForActivities } from 'store/agentActivities/action';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { UserDto } from '../../../types/user';

import { UserModalFormData } from '../../Users/types';
import '../../Users/styles.scss';

import ActionButtons from '../ActionButtons';
import GroupsOfAgents from '../GroupsOfAgents';
import { UnlockOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { signInAsAgent } from '../../../store/user/action';
import { openNotification } from '../../../helpers/globalFunctions';
import moment from 'moment';
import Total from 'components/Shared/Total';
import { debounce } from 'lodash';

interface AgentsTableInterFace {
  setCurrentAgent: (agentData: UserModalFormData) => void;
  setModalVisible: (visibleState: boolean) => void;
}

const AgentTable: React.FC<AgentsTableInterFace> = ({
  setCurrentAgent,
  setModalVisible,
}) => {
  const dispatch = useDispatch();
  const agents = useSelector(agentsSelector);
  const loading = useSelector(agentsLoadingSelector);
  const total = useSelector(agentsTotalSelector);
  const agentDeletedSuccessfully = useSelector(agentDeleteSuccessSelector);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [sortOptions, setSortOptions] = useState({
    sortOrder: 'desc',
    sortField: 'updated_at',
    searchValue: '',
  });

  function confirm(agentData: UserDto) {
    Modal.confirm({
      title: 'Confirm Agent deletion!',
      icon: <ExclamationCircleOutlined />,
      content: `Do you really want to delete agent ${agentData.name} with mail ${agentData.email} `,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => {
        dispatch(deleteAgent(agentData._id));
      },
    });
  }
  const delayGetAgents = debounce((...rest) => {
    dispatch(getAgents(...rest));
  }, 500);

  const columns = [
    { dataIndex: 'name', key: 'name', title: 'Name', sorter: true },
    {
      dataIndex: 'source',
      key: 'source',
      title: 'Source',
      sorter: true,
      render: (_id: fieldDto, rowData: UserDto) => rowData.source && rowData.source.name ? rowData.source.name : 'RentBase',
    },
    {
      dataIndex: '__v',
      key: '__v',
      title: 'Listings',
      render: (_id: fieldDto, rowData: UserDto) =>
        rowData?.listingsOfAgent?.length,
    },
    {
      dataIndex: 'updated_at',
      key: 'updated_at',
      title: 'Landlords',
      render: (_id: fieldDto, rowData: UserDto) =>
        rowData?.landlordsListOfAgent?.[0]?.landlords_list?.length || 0,
    },
    {
      dataIndex: '__v',
      key: '__v',
      title: 'Leads',
      render: (_id: fieldDto, rowData: UserDto) => rowData?.leads?.length || 0,
    },
    {
      dataIndex: '__v',
      key: '__v',
      title: 'Follow ups',
      render: (_id: fieldDto, rowData: UserDto) =>
        rowData?.follow_ups ? rowData?.follow_ups[0]?.total || 0 : 0,
    },
    {
      dataIndex: 'created_at',
      key: 'created_at',
      title: 'Joined',
      render: (created_at: Date) => {
        return moment(created_at).format('MM-DD-YYYY');
      },
    },
    {
      dataIndex: 'last_visit',
      key: 'last_visit',
      title: 'Last Login',
      render: (last_visit: Date) => {
        return last_visit
          ? moment(last_visit).format('MM-DD-YYYY')
          : "Didn't login yet";
      },
    },
    {
      dataIndex: 'groupsOfAgents',
      key: 'groupsOfAgents',
      title: 'Groups Of Agent',
      render: (_id: fieldDto, rowData: any) => {
        return <GroupsOfAgents groupsOfAgent={rowData['groupsOfAgent']} />;
      },
    },
    {
      dataIndex: '_id',
      key: '_id',
      title: 'Actions',
      render: (_id: fieldDto, rowData: UserDto) => (
        <div>
          <ActionButtons
            rowData={rowData}
            openEditModal={(rowData: any) => {
              setCurrentAgent(rowData);
              setModalVisible(true);
            }}
            openDeleteConfirmModal={(agentData: UserDto) => {
              confirm(agentData);
            }}
          />
        </div>
      ),
    },
    {
      dataIndex: '_id',
      key: '_id',
      title: 'Activities',
      render: (_id: Date, rowData: UserDto) => {
        return (
          <div className='flex-centered'>
            <NavLink
              to={`/activities/${rowData.agent_id}`}
              onClick={() => dispatch(setCurrentAgentForActivities(rowData))}
            >
              Activities
            </NavLink>
          </div>
        );
      },
    },
    {
      dataIndex: 'agent_id',
      key: 'agent_id',
      title: 'Sign In As',
      render: (_: string, row: IObjectKeys) => {
        return (
          <Button
            icon={<UnlockOutlined />}
            onClick={() => {
              if (row?._id) {
                dispatch(signInAsAgent(row._id));
              } else {
                openNotification('error', 'Agent does not exist.');
              }
            }}
          >
            Sign In As
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    delayGetAgents(
      pagination.current,
      pagination.pageSize,
      sortOptions.sortField,
      sortOptions.sortOrder,
      sortOptions.searchValue,
    );
  }, [
    dispatch,
    pagination,
    sortOptions.sortField,
    sortOptions.sortOrder,
    sortOptions.searchValue,
  ]);

  useEffect(() => {
    if (agentDeletedSuccessfully) {
      dispatch(
        getAgents(
          pagination.current,
          pagination.pageSize,
          sortOptions.sortField,
          sortOptions.sortOrder,
          sortOptions.searchValue,
          true,
          true,
        ),
      );
    }
  }, [agentDeletedSuccessfully]);

  const handleTableChange = (
    paginationOpt: TablePaginationConfig,
    _: any,
    sorter: any,
  ): void => {
    setPagination({
      current: paginationOpt.current,
      pageSize: paginationOpt.pageSize,
    });
    setSortOptions((prevState) => ({
      ...prevState,
      sortField: sorter.field,
      sortOrder: sorter.order,
    }));
  };

  return (
    <div>
        <Input.Search
        className={`search-users-input`}
        value={sortOptions.searchValue}
        onChange={(e) => {
          const value = e.target.value;
          setSortOptions((prevState) => ({
            ...prevState,
            searchValue: value,
          }));
        }}
      />
      <Total total={total} />
      <Table
        columns={columns}
        dataSource={agents}
        rowClassName={(record) => (!record.is_active ? 'deactivated-row' : '')}
        rowKey={(record: any) => record._id}
        onChange={handleTableChange}
        pagination={{ ...pagination, total }}
        loading={loading}
        scroll={{ x: 1300 }}
      />
    </div>
  );
};
export default AgentTable;
