import { UserModalFormData } from '../../components/Users/types';

import * as types from './types';
import { UserDto } from '../../types/user';
import {IObjectKeys} from "../../helpers/globalTypes";

export function getAgentsGroups(
  page: number = 1,
  size: number = 10,
  sortField: string = '',
  sortOrder: string = '',
  searchValue: string = '',
  agentsOnly: boolean = false,
) {

  return {
    type: types.GET_AGENT_GROUPS,
    payload: {
      page, size, sortField, sortOrder, searchValue,
    },
  };
}

export function getAgentsGroupsSuccess(users: object) {
  return { type: types.GET_AGENT_GROUPS_SUCCESS, payload: users };
}

export function getAgentsGroupsFail(error: object) {
  return { type: types.GET_AGENT_GROUPS_FAIL, payload: error };
}

export function updateAgentsGroup(payload: UserModalFormData) {
  return {
    type: types.UPDATE_AGENT_GROUP,
    payload,
  };
}

export function updateAgentsGroupSuccess(payload: any) {
  return {
    type: types.UPDATE_AGENT_GROUP_SUCCESS,
    payload,
  };
}

export function updateAgentsGroupFail(payload: any) {
  return {
    type: types.UPDATE_AGENT_GROUP_FAIL,
    payload,
  };
}

export function addAgentsGroup(payload: any) {
  return {
    type: types.ADD_AGENT_GROUP,
    payload,
  };
}

export function addAgentsGroupSuccess(payload: any) {
  return {
    type: types.ADD_AGENT_GROUP_SUCCESS,
    payload,
  };
}

export function addAgentsGroupFail(payload: any) {
  return {
    type: types.ADD_AGENT_GROUP_FAIL,
    payload,
  };
}

export function getAgents(
  size: number = 10,
  searchValue: string = '',
) {
  return {
    type: types.GET_AGENTS,
    payload: {
      size, searchValue,
    },
  };
}

export function getAgentsSuccess(agents: object) {
  return { type: types.GET_AGENTS_SUCCESS, payload: agents };
}

export function getAgentsFail(error: object) {
  return { type: types.GET_AGENTS_FAIL, payload: error };
}

export function softDeleteAgentsGroupSuccess(payload: UserDto) {
  return {
    type: types.SOFT_DELETE_AGENT_GROUP_SUCCESS,
    payload
  };
}
export function softDeleteAgentsGroupFail(payload: any) {
  return {
    type: types.SOFT_DELETE_AGENT_GROUP_FAIL,
    payload
  };
}
export function softDeleteAgentsGroup(payload: string) {
  return {
    type: types.SOFT_DELETE_AGENT_GROUP,
    payload
  };
}

export function setAgentGroupSubscription(payload:IObjectKeys){
  return {
    type: types.SET_AGENT_GROUP_TO_SUBSCRIPTION,
    payload
  }
}
export function setAgentGroupSubscriptionSuccess(payload:IObjectKeys){
  return {
    type: types.SET_AGENT_GROUP_TO_SUBSCRIPTION_SUCCESS,
    payload
  }
}
export function setAgentGroupSubscriptionFail(payload:IObjectKeys){
  return {
    type: types.SET_AGENT_GROUP_TO_SUBSCRIPTION_FAIL,
    payload
  }
}

export function cancelAgentGroupSubscription(payload:IObjectKeys){
  return {
    type: types.CANCEL_AGENT_GROUP_SUBSCRIPTION,
    payload
  }
}
export function cancelAgentGroupSubscriptionSuccess(payload:IObjectKeys){
  return {
    type: types.CANCEL_AGENT_GROUP_SUBSCRIPTION_SUCCESS,
    payload
  }
}
export function cancelAgentGroupSubscriptionFail(payload:IObjectKeys){
  return {
    type: types.CANCEL_AGENT_GROUP_SUBSCRIPTION_FAIL,
    payload
  }
}

export function setStatusToFalse(){
  return{
    type:types.SET_STATUS_TO_FALSE
  }
}


