import Api from './api';
import { AxiosPromise } from 'axios';
import { AuthError, UserDto } from '../types/user';
import { IObjectKeys } from '../helpers/globalTypes';

interface usersData {
  total: number;
  data: UserDto[];
}

export default class UsersApi {
  /* Users Api's start*/
  static getUsers(params: any): AxiosPromise<usersData | AuthError> {
    const api = new Api('users');
    return api.get('', { params });
  }

  static addUser(data: any): AxiosPromise<usersData | AuthError> {
    const api = new Api('users');
    return api.create({ data });
  }

  static updateUser(data: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api('users');
    return api.put('', { data: data.data, params: data.params });
  }

  static softDeleteUser(_id: string): AxiosPromise<UserDto | AuthError> {
    const api = new Api('users');
    return api.destroy('', { params: { _id } });
  }
  static forceDeleteUser(_id: string): AxiosPromise<UserDto | AuthError> {
    const api = new Api('users/force_delete_user');
    return api.destroy(_id);
  }

  static recoverUser(_id: string): AxiosPromise<UserDto | AuthError> {
    const api = new Api('users/recover');
    return api.put(_id, {});
  }
  /* Users Api's end*/

  /* Agents APis start */
  static getAgents(params: any): AxiosPromise<usersData | AuthError> {
    const api = new Api('users');
    return api.get('', { params });
  }

  static addAgent(data: any): AxiosPromise<usersData | AuthError> {
    const api = new Api('users');
    return api.create({ data });
  }
  /* Agents APis end */
}

