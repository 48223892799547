import React, { useEffect, useState } from 'react';
import { Input, Table } from 'antd';
import { fieldDto } from 'helpers/globalTypes';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
  forceDeleteUserSuccessSelector,
  usersLoadingSelector,
  usersSelector,
  usersTotalSelector,
} from 'store/users/selectors';
import { getUsers } from 'store/users/action';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import { UserDto } from '../../../types/user';
import ActionButtons from '../ActionButtons';
import { UserModalFormData } from '../types';

import '../styles.scss';
import { ProfilesOfUser } from '../ProfilesOfUser';

interface UsersTableInterFace {
  setCurrentUser: (userData: UserModalFormData) => void;
  setModalVisible: (visibleState: boolean) => void;
}

const UsersTable: React.FC<UsersTableInterFace> = ({
  setCurrentUser,
  setModalVisible,
}) => {
  const dispatch = useDispatch();
  const users = useSelector(usersSelector);
  const loading = useSelector(usersLoadingSelector);
  const total = useSelector(usersTotalSelector);
  const forceDeleteUserSuccess = useSelector(forceDeleteUserSuccessSelector);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [sortOptions, setSortOptions] = useState({
    sortOrder: 'desc',
    sortField: 'updated_at',
    searchValue: '',
  });

  const delayGetUsers = debounce((...rest) => {
    dispatch(getUsers(...rest));
  }, 500);

  const columns = [
    { dataIndex: 'name', key: 'name', title: 'Name', sorter: true },
    { dataIndex: 'email', key: 'email', title: 'Email', sorter: true },
    {
      dataIndex: 'phone',
      key: 'email',
      title: 'Phone',
      sorter: true,
      render: (phone: fieldDto) =>
        phone ? phone.toString() : 'No Phone Number',
    },
    {
      dataIndex: '_v',
      key: '_v',
      title: 'Profiles',
      sorter: false,
      render: (_id: fieldDto, rowData: UserDto) => (
        <ProfilesOfUser user={rowData} />
      ),
    },
    {
      dataIndex: '_id',
      key: '_id',
      title: 'Actions',
      render: (_id: fieldDto, rowData: UserDto) => (
        <div>
          <ActionButtons
            userData={rowData}
            openEditModal={(userData: UserModalFormData) => {
              setCurrentUser(userData);
              setModalVisible(true);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    delayGetUsers(
      pagination.current,
      pagination.pageSize,
      sortOptions.sortField,
      sortOptions.sortOrder,
      sortOptions.searchValue,
    );
  }, [
    dispatch,
    pagination,
    sortOptions.sortField,
    sortOptions.sortOrder,
    sortOptions.searchValue,
  ]);

  useEffect(() => {
    if (forceDeleteUserSuccess) {
      dispatch(
        getUsers(
          pagination.current,
          pagination.pageSize,
          sortOptions.sortField,
          sortOptions.sortOrder,
          sortOptions.searchValue,
        ),
      );
    }
  }, [forceDeleteUserSuccess]);

  const handleTableChange = (
    paginationOpt: TablePaginationConfig,
    _: any,
    sorter: any,
  ): void => {
    setPagination({
      current: paginationOpt.current,
      pageSize: paginationOpt.pageSize,
    });
    setSortOptions((prevState) => ({
      ...prevState,
      sortField: sorter.field,
      sortOrder: sorter.order,
    }));
  };

  return (
    <div className='users-table-wrapper'>
      <Input.Search
        className={`search-users-input`}
        value={sortOptions.searchValue}
        onChange={(e) => {
          const value = e.target.value;
          setSortOptions((prevState) => ({
            ...prevState,
            searchValue: value,
          }));
        }}
      />

      <Table
        columns={columns}
        dataSource={users}
        rowClassName={(record) => (!record.is_active ? 'deactivated-row' : '')}
        rowKey={(record) => record._id}
        onChange={handleTableChange}
        pagination={{ ...pagination, total }}
        loading={loading}
      />
    </div>
  );
};
export default UsersTable;
