import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { Route, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes/routesCode';
import { useAppContext } from 'libs/contextLib';
import AuthLayout from 'layouts/auth/AuthLayout';
import { getLoggedInUser, signOut } from 'store/user/action';
import { userSelector } from '../../store/user/selectors';
import { getAccessToken } from '../../helpers/utils';
import { LoadScriptNext } from "@react-google-maps/api";
import { googleMapsApiKey } from '../../helpers/constants';
import { Libraries } from 'components/Shared/PlacesAutocomplete/types';
export interface AuthRouteProps extends RouteProps {
  component: React.JSXElementConstructor<any>;
}
export const googleMapsApiLibrariesArray: Libraries = ["places"];

const AuthRoute: React.FC<AuthRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { isAuthenticated, userHasAuthenticated } = useAppContext();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    async function onLoad() {
      try {
        if (getAccessToken()) {
          dispatch(getLoggedInUser());
          userHasAuthenticated(true);

        } else {
          dispatch(signOut());
          userHasAuthenticated(false);

        }
      } catch (e) {
        dispatch(push(routes.home.path));
      }
    }

    onLoad();
  }, [userHasAuthenticated, dispatch]);


  return isAuthenticated ? (
    <Route
      {...rest}
      render={(...renderProps) => (
        <AuthLayout>
          <LoadScriptNext
            id="script-loader"
            googleMapsApiKey={googleMapsApiKey}
            libraries={googleMapsApiLibrariesArray}
          >
            <Component {...renderProps} />
          </LoadScriptNext >
        </AuthLayout>
      )}
    />
  ) : null;
};

export default AuthRoute;
