import React from 'react';
import { Modal } from 'antd';

import { IObjectKeys } from '../../../helpers/globalTypes';

interface AgentActivities {
  visible: boolean;
  setVisible: (visibleState: boolean) => void;
  modalType?: string;
  activitiesData?: IObjectKeys
}


const AgentActivitiesModal: React.FC<AgentActivities> = ({
                                                           activitiesData,
                                                           visible,
                                                           setVisible,
                                                           modalType
}) => {

  return (
    <Modal
      title={modalType}
      footer={null}
      visible={visible}
      width={320}
      onCancel={() => {
        setVisible(false);
      }}
    >
      {activitiesData && JSON.stringify(activitiesData)}
    </Modal>
  );
};
export default AgentActivitiesModal;
