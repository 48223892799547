import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import UsersApi from '../../api/Users';
import {
  ADD_USER, FORCE_DELETE_USER,
  GET_USERS, RECOVER_USER, SOFT_DELETE_USER, UPDATE_USER,
} from './types';
import {
  addUserFail,
  addUserSuccess, forceDeleteUserFail, forceDeleteUserSuccess,
  getUsersFail,
  getUsersSuccess, recoverUserFail, recoverUserSuccess, softDeleteUserFail, softDeleteUserSuccess,
  updateUserFail,
  updateUserSuccess,
} from './action';

function* getUsersSaga({ payload }: any) {
  try {
    const response = yield call(
      UsersApi.getUsers, { ...payload, sortOrder: payload.sortOrder, sortField: payload.sortField },
    );
    yield put(getUsersSuccess(response));
  } catch (e) {
    yield put(getUsersFail(e));
  }
}

function* addUserSaga({ payload }: any ) {
  try {
    const response = yield call(
      UsersApi.addUser, payload,
    );
    yield put(addUserSuccess(response));
  } catch (e) {
    yield put(addUserFail(e));
  }
}

function* updateUserSaga({ payload }: any ) {
  try {
    const response = yield call(
      UsersApi.updateUser, { params: { _id: payload._id }, data: { ...payload } },
    );
    yield put(updateUserSuccess(response));
  } catch (e) {
    yield put(updateUserFail(e));
  }
}

function* softDeleteUserSaga({ payload }: any) {
  try {
    const response = yield call(
      UsersApi.softDeleteUser, payload,
    );
    yield put(softDeleteUserSuccess(response));
  } catch (e) {
    yield put(softDeleteUserFail(e));
  }
}

function* forceDeleteUserSaga({ payload }: any) {
  try {
    const response = yield call(
      UsersApi.forceDeleteUser, payload,
    );
    yield put(forceDeleteUserSuccess(response));
  } catch (e) {
    yield put(forceDeleteUserFail(e));
  }
}
function* recoverUserSaga({ payload }: any) {
  try {
    const response = yield call(
      UsersApi.recoverUser, payload,
    );
    if (response.success) {
      yield put(recoverUserSuccess(payload));
    }else {
      throw new Error('Something went wrong.');
    }
  } catch (e) {
    yield put(recoverUserFail(e));
  }
}
function* watchGetLoggedInUser() {
  yield takeLatest(GET_USERS, getUsersSaga);
}

function* watchAddUser() {
  yield takeLatest(ADD_USER, addUserSaga);
}

function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUserSaga);
}

function* watchSoftDeleteUserSaga() {
  yield takeLatest(SOFT_DELETE_USER, softDeleteUserSaga);
}

function* watchForceDeleteUserSaga() {
  yield takeLatest(FORCE_DELETE_USER, forceDeleteUserSaga);
}

function* watchRecoverUserSaga() {
  yield takeLatest(RECOVER_USER, recoverUserSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetLoggedInUser),
    fork(watchAddUser),
    fork(watchUpdateUser),
    fork(watchSoftDeleteUserSaga),
    fork(watchRecoverUserSaga),
    fork(watchForceDeleteUserSaga),
  ]);
}
