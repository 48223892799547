import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { fieldDto } from 'helpers/globalTypes';

import { AgentsGroupDto } from '../../../types/user';

import ActionButtons from '../ActionButtons';
import AgentsOnGroup from '../AgentsOnGroup/index';

import {
  agentsGroupsSelector,
  agentsGroupLoadingSelector,
  agentsGroupsTotalSelector,
} from 'store/agentsGroups/selectors';
import { getUsers } from 'store/users/action';
import { getAgentsGroups } from 'store/agentsGroups/action';
import { TablePaginationConfig } from 'antd/lib/table/interface';
import Total from 'components/Shared/Total';
import SubscriptionStatus from '../Subscription/SubscriptionStatus';
import moment from 'moment';

interface AgentsGroupsTableInterFace {
  setCurrentAgentsGroup: (data: any) => void;
  setModalVisible: (visibleState: boolean) => void;
}

const AgentsGroupsTable: React.FC<AgentsGroupsTableInterFace> = ({
  setCurrentAgentsGroup,
  setModalVisible,
}) => {
  const dispatch = useDispatch();
  const agentsGroups = useSelector(agentsGroupsSelector);
  const loading = useSelector(agentsGroupLoadingSelector);
  const total = useSelector(agentsGroupsTotalSelector);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  });
  const [sortOptions, setSortOptions] = useState({
    sortOrder: 'desc',
    sortField: 'updated_at',
  });

  const columns = [
    {
      dataIndex: 'agents_group_name',
      key: 'agents_group_name',
      title: 'Name',
      sorter: true,
    },
    {
      dataIndex: 'description',
      key: 'description',
      title: 'Description',
      sorter: true,
    },
    {
      dataIndex: 'subscription',
      key: 'subscription',
      title: 'Subscription Status',
      render: (_id: fieldDto, rowData: AgentsGroupDto) => (
        <SubscriptionStatus agent={rowData} />
      ),
    },
    {
      dataIndex: 'expiration_date',
      key: 'expiration_date',
      title: 'Expiration Date',
      render: (_id: fieldDto, rowData: any) => (
        <div>
          <span>
            {rowData.subscription_level !== 'free'
              ? moment(rowData?.subscription?.end_date_timestamp).format(
                  'MM-DD-YYYY',
                )
              : 'No Date'}
          </span>
        </div>
      ),
    },
    {
      dataIndex: 'agents',
      key: 'agents',
      title: 'Agents',
      render: (_id: fieldDto, rowData: AgentsGroupDto) => (
        <AgentsOnGroup agentsOnGroup={rowData['agentsOnGroup']} />
      ),
    },
    {
      dataIndex: '_id',
      key: '_id',
      title: 'Actions',
      render: (_id: fieldDto, rowData: AgentsGroupDto) => (
        <div>
          <ActionButtons
            data={rowData}
            openEditModal={(data: any) => {
              setCurrentAgentsGroup(data);
              setModalVisible(true);
            }}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      getUsers(
        pagination.current,
        pagination.pageSize,
        sortOptions.sortField,
        sortOptions.sortOrder,
      ),
    );

    dispatch(
      getAgentsGroups(
        pagination.current,
        pagination.pageSize,
        sortOptions.sortField,
        sortOptions.sortOrder,
      ),
    );
  }, [dispatch, pagination, sortOptions.sortField, sortOptions.sortOrder]);

  const handleTableChange = (
    paginationOpt: TablePaginationConfig,
    _: any,
    sorter: any,
  ): void => {
    setPagination({
      current: paginationOpt.current,
      pageSize: paginationOpt.pageSize,
    });
    setSortOptions({
      sortField: sorter.field,
      sortOrder: sorter.order,
    });
  };

  return (
    <div>
      <Total total={total} />
      <Table
        columns={columns}
        dataSource={agentsGroups}
        rowClassName={(record) => (!record.is_active ? 'deactivated-row' : '')}
        // @ts-ignore
        rowKey={(record) => record._id}
        onChange={handleTableChange}
        pagination={{ ...pagination, total }}
        loading={loading}
      />
    </div>
  );
};
export default AgentsGroupsTable;
