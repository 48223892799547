export const PROFILE_TYPES_ARR = ['landlord', 'renter', 'agent'];
export const RB_PORTAL_URL =
  process.env.REACT_APP_PORTAL_URL || 'https://rentbase-portal.herokuapp.com';

export const USER_MODAL_PHONE_OR_EMAIL =
  'Phone or Email is requierd, please fill in at least one of them';

export const PHONE_NUMBER_MASK = '+(999) 999-9999';
export const PHONE_NUMBER_PLACEHOLDER = '+ (___) ___-____';

export const US_TIME_FORMAT = 'hh:mm A'
export const US_DATE_FORMAT = 'MM-DD-YYYY';
export const US_DATE_FORMAT_WITH_TIME = 'MM-DD-YYYY hh:mm A';
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY || "";
