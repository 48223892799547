import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Index from '../../components/AgentsGroups/AgentsGroupsModal';
import AgentsGroupsTable from '../../components/AgentsGroups/AgentsGroupsTable';
import {
  agentsGroupSaveSuccessSelector,
  agentsGroupUpdateSuccessSelector,
} from '../../store/agentsGroups/selectors';

const AgentsGroups = () => {
  const agentsGroupSaveSuccess = useSelector(agentsGroupSaveSuccessSelector);
  const agentsGroupUpdateSuccess = useSelector(
    agentsGroupUpdateSuccessSelector,
  );
  const [currentAgentsGroup, setCurrentAgentsGroup] = useState({});
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (agentsGroupSaveSuccess || agentsGroupUpdateSuccess) {
      setVisible(false);
      setCurrentAgentsGroup({});
    }
  }, [agentsGroupSaveSuccess, agentsGroupUpdateSuccess]);
  return (
    <div>
      <div className='page-header-wrapper'>
        <h1 className='font-24'>Agents Groups</h1>
        <div>
          <Button
            type='primary'
            size='large'
            onClick={() => {
              setCurrentAgentsGroup({});
              setVisible(true);
            }}
          >
            <PlusOutlined className='font-24' />
          </Button>
        </div>
      </div>

      <AgentsGroupsTable
        setCurrentAgentsGroup={(currentAgentsGroupData) =>
          setCurrentAgentsGroup(currentAgentsGroupData)
        }
        setModalVisible={(visibleState) => setVisible(visibleState)}
      />
      <Index
        visible={visible}
        currentAgentsGroupData={currentAgentsGroup}
        setVisible={setVisible}
        modalType='Agents Group'
      />
    </div>
  );
};
export default AgentsGroups;
