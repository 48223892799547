import Api from './api';
import { AxiosPromise } from 'axios';
import { AuthError, UserDto, AgentsGroupDto } from '../types/user';
import { IObjectKeys } from '../helpers/globalTypes';


export default class AgentsGroupsApi {
  static getAgentsGroups(params: any): any {
    const api = new Api('agents-group');
    return api.get('', { params });
  }

  static addAgentsGroup(data: any): any {
    const api = new Api('agents-group');
    return api.create({ data });
  }

  static updateAgentsGroup(data: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api('agents-group');
    return api.put('', { data: data.data, params: data.params });
  }
  static updateSubscriptionStatus(data: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api('admin/agent/premium');
    console.log(data)
    return api.create({data:{
        agencyId: data.agencyId,
        trialDays: data.trialDays
      }});
  }
  static getAgents(params: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api('agents-group');
    return api.get('agents', { params });
  }

  static softDeleteAgentsGroup(_id: string): AxiosPromise<AgentsGroupDto | AuthError> {
    const api = new Api('agents-group');
    return api.destroy('', { params: { _id } });
  }
  static removePremiumSubscription(_id: any): AxiosPromise<AgentsGroupDto | AuthError> {
    const api = new Api('admin/agent/premium');
    return api.destroy('', { params: {},data:{agencyId:_id} });
  }
}

