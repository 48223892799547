import React, { useEffect, useState } from 'react';
import AgentTable from '../../components/Agents/AgentTable';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import AgentModal from '../../components/Agents/AgentsModal';
import {
  agentSaveSuccessSelector,
  agentUpdateSuccessSelector,
} from '../../store/agents/selectors';

const Agents = () => {
  const agentSaveSuccess = useSelector(agentSaveSuccessSelector);
  const agentUpdateSuccess = useSelector(agentUpdateSuccessSelector);
  const [currentAgent, setCurrentAgent] = useState({});
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    if (agentSaveSuccess || agentUpdateSuccess) {
      setVisible(false);
      setCurrentAgent({});
    }
  }, [agentSaveSuccess, agentUpdateSuccess]);
  return (
    <div>
      <div className='page-header-wrapper'>
        <h1 className='font-24'>Agents</h1>
        <div>
          <Button
            type='primary'
            size='large'
            onClick={() => {
              setCurrentAgent({});
              setVisible(true);
            }}
          >
            <PlusOutlined className='font-24' />
          </Button>
        </div>
      </div>

      <AgentTable
        setCurrentAgent={(agentData) => {
          return setCurrentAgent(agentData);
        }}
        setModalVisible={(visibleState) => setVisible(visibleState)}
      />
      <AgentModal
        setVisible={(state) => setVisible(state)}
        visible={visible}
        userData={currentAgent}
        modalType='Agent'
      />
    </div>
  );
};
export default Agents;
