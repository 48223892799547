import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Select, Input, Button } from 'antd';
import { IObjectKeys } from '../../../helpers/globalTypes';
import { UserModalFormData } from '../types';
import { addUser, updateUser } from '../../../store/users/action';
import {
  PHONE_NUMBER_MASK,
  PHONE_NUMBER_PLACEHOLDER,
  PROFILE_TYPES_ARR,
  USER_MODAL_PHONE_OR_EMAIL,
} from '../../../helpers/constants';
import { usersErrorSelector } from 'store/users/selectors';
import { NOT_REQUIRED_PHONE_VALIDATION, PHONE_NUMBER_VALIDATION } from '../../../helpers/validations';
import MaskedInput from '../../Shared/MaskedPhoneInput';
import { sanitizePhoneNumber } from '../../../helpers/globalFunctions';

interface UserModalInterface {
  userData: UserModalFormData;
  modalType: string; // or user or agent
  visible: boolean;
  setVisible: (visibleState: boolean) => void;
}

interface userFormData extends IObjectKeys {
  name: string;
  phone: string;
  email: string;
  profiles: IObjectKeys;
}

const { Option } = Select;
const UserModal: React.FC<UserModalInterface> = ({ userData, visible, setVisible, modalType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const usersError = useSelector(usersErrorSelector);
  useEffect(() => {
    if (usersError && usersError.statusCode && usersError.statusCode === 403) {
      form.setFields([
        {
          name: 'profiles',
          errors: [usersError.message],
        },
      ]);
    }
    if (usersError && !usersError.statusCode) {
      const fieldsErrorsToSet = Object.keys(usersError).map((key: string) => {
        return {
          name: key,
          errors: [usersError[key]],
        };
      });
      form.setFields([...fieldsErrorsToSet]);
    }
  }, [usersError]);

  useEffect(() => {

    if (userData && Object.keys(userData).length) {
      for (let fieldName in userData) {
        if (fieldName && userData.hasOwnProperty(fieldName)) {
          if (fieldName !== 'profiles') {
            form.setFieldsValue({ [fieldName]: userData[fieldName] });
          } else {
            const profiles = [];
            for (const profile in userData.profiles) {
              if (userData.profiles[profile]) {
                profiles.push(profile);
              }
            }
            form.setFieldsValue({ profiles });
          }
        }
      }
    } else {
      form.resetFields();
    }
  }, [form, userData]);

  const resetPhoneOrEmailError = () => {
    form.setFields([
      {
        name: 'phone',
        errors: [],
      },
      {
        name: 'email',
        errors: [],
      },
    ]);
  };

  const handleSubmit = (formData: userFormData) => {
    if (formData.profiles?.indexOf('agent') > -1 && !formData.email) {
      form.setFields([
        {
          name: 'email',
          errors: ['Email is Required if user is Agent'],
        }]);
      return;
    }
    if (!formData.email && !formData.phone) {
      form.setFields([
        {
          name: 'email',
          errors: [USER_MODAL_PHONE_OR_EMAIL],
        },
        {
          name: 'phone',
          errors: [USER_MODAL_PHONE_OR_EMAIL],
        },
      ]);
      return;
    }
    if( !formData.profiles ) formData.profiles = [];
    if (formData.phone) {
      formData.phone = sanitizePhoneNumber(formData.phone);
    }
    if (userData._id) {
      userData.profiles && Object.keys(userData.profiles).map((item: any) => {
        if (item && userData.profiles && userData.profiles.hasOwnProperty(item)) {
          userData.profiles[item] = formData.profiles.indexOf(item) > -1;
        }
      });
      dispatch(updateUser({ ...formData, manageUsersPage: true, profiles: userData.profiles, _id: userData._id }));
    } else {
      if (modalType === 'user') {
        const profiles: any = {};
        PROFILE_TYPES_ARR.map((item: string) => {
          return profiles[item] = formData.profiles.indexOf(item) > -1;
        });
        dispatch(addUser({ ...formData, manageUsersPage: true, profiles }));
      } else {
        const profiles: any = { agent: true, renter: false, landlord: false };
        dispatch(addUser({ ...formData, manageUsersPage: true, profiles }));
      }
    }
  };
  return (
    <Modal
      title={(userData && userData._id ? 'Edit ' : 'Add ') + modalType}
      footer={null}
      visible={visible}
      width={320}
      onCancel={() => {
        form.resetFields();
        setVisible(false);
      }}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
      >
        <Form.Item label='Name' name='name'
                   rules={[{ required: true, min: 4, max: 20, message: 'Required field, format - John Doe' }]}>
          <Input placeholder='Name' />
        </Form.Item>
        <Form.Item
          name='phone'
          label='Phone Number'
          rules={[NOT_REQUIRED_PHONE_VALIDATION]}
        >
          <MaskedInput
            mask={PHONE_NUMBER_MASK}
            placeholder={PHONE_NUMBER_PLACEHOLDER}
            onChange={() => resetPhoneOrEmailError()}
            validateTrigger={'onBlur'}
          />
        </Form.Item>
        <Form.Item label='Email' name='email'
                   rules={[{ type: 'email', message: 'Required field, format - mail@gmail.com' }]}>
          <Input
            placeholder='Email'
            onChange={() => resetPhoneOrEmailError()}
          />
        </Form.Item>
        {(modalType === 'user') ?
          <Form.Item label='Profiles' name='profiles'>
            <Select mode='multiple' placeholder='Please Select Profiles'>
              <Option value='agent'>Agent</Option>
              <Option value='renter'>Renter</Option>
              <Option value='landlord'>LandLord</Option>
            </Select>
          </Form.Item>
          :
          <Form.Item label='Invitation Text' name='invitationText'>
            <Input.TextArea placeholder='Invitation Text' />
          </Form.Item>
        }
        <Form.Item>
          <Button type='primary' htmlType='submit'>{(userData && userData._id ? 'Save' : 'Add')}</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UserModal;
