import React, { Fragment } from 'react';

const AgentsOnGroup = ({ agentsOnGroup }: any) => {
  return (
    <div>
      {
        agentsOnGroup.map((agent: any, i: number) => {
          return (
            <span key={agent._id}>
              {agent.name}
              {(i !== (agentsOnGroup.length - 1)) && <span >,   </span>}
            </span>
          );
        })
      }
    </div>
  );
};

export default AgentsOnGroup;
