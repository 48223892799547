
export const GET_GROUPS_OF_AGENT = 'GET_GROUPS_OF_AGENT';
export const GET_GROUPS_OF_AGENT_SUCCESS = 'GET_GROUPS_OF_AGENT_SUCCESS';
export const GET_GROUPS_OF_AGENT_FAIL = 'GET_GROUPS_OF_AGENT_FAIL';

export const UPDATE_AGENT = 'UPDATE_AGENT';
export const UPDATE_AGENT_SUCCESS = 'UPDATE_AGENT_SUCCESS';
export const UPDATE_AGENT_FAIL = 'UPDATE_AGENT_FAIL';

export const CLEAN_DEBOUNCE = 'CLEAN_DEBOUNCE';

export const GET_AGENT = 'GET_AGENT';
export const GET_AGENT_SUCCESS = 'GET_AGENT_SUCCESS';
export const GET_AGENT_FAIL = 'GET_AGENT_FAIL';

export const GET_ALL_AGENTS = 'GET_ALL_AGENTS';
export const GET_ALL_AGENTS_SUCCESS = 'GET_ALL_AGENTS_SUCCESS';

export const ADD_AGENT = 'ADD_AGENT';
export const ADD_AGENT_SUCCESS = 'ADD_AGENT_SUCCESS';
export const ADD_AGENT_FAIL = 'ADD_AGENT_FAIL';

export const FORCE_DELETE_AGENT = 'FORCE_DELETE_AGENT';
export const FORCE_DELETE_AGENT_SUCCESS = 'FORCE_DELETE_AGENT_SUCCESS';
export const FORCE_DELETE_AGENT_FAIL = 'FORCE_DELETE_AGENT_FAIL';

export const DELETE_AGENT = 'DELETE_AGENT';
export const DELETE_AGENT_SUCCESS = 'DELETE_AGENT_SUCCESS';
export const DELETE_AGENT_FAIL = 'DELETE_AGENT_FAIL';

export const RESEND_CONFIRMATION_LINK = 'RESEND_CONFIRMATION_LINK_AGENT';
export const RESEND_CONFIRMATION_LINK_SUCCESS = 'RESEND_CONFIRMATION_LINK_SUCCESS';
export const RESEND_CONFIRMATION_LINK_FAIL = 'RESEND_CONFIRMATION_LINK_FAIL';

export const SEND_FOLLOW_UP = 'SEND_FOLLOW_UP';
export const SEND_FOLLOW_UP_SUCCESS = 'SEND_FOLLOW_UP_SUCCESS';