import { RootStateOrAny } from 'react-redux';

export const groupsOfAgentSelector = (state: RootStateOrAny) => state.agentsReducer.groupsOfAgent;
export const groupsOfAgentLoadingSelector = (state: RootStateOrAny) => state.agentsReducer.groupsOfAgentLoading;
export const agentsSelector = (state: RootStateOrAny) => state.agentsReducer.agents;
export const allAgentsSelector = (state: RootStateOrAny) => state.agentsReducer.allAgents;
export const agentsErrorSelector = (state: RootStateOrAny) => state.agentsReducer.agentsError;
export const agentSaveSuccessSelector = (state: RootStateOrAny) => state.agentsReducer.agentSaveSuccess;
export const agentUpdateSuccessSelector = (state: RootStateOrAny) => state.agentsReducer.agentUpdateSuccess;
export const agentsLoadingSelector = (state: RootStateOrAny) => state.agentsReducer.agentsLoading;
export const followUpLoadingSelector = (state: RootStateOrAny) => state.agentsReducer.followUpLoading;
export const agentsTotalSelector = (state: RootStateOrAny) => state.agentsReducer.total;
export const agentDeleteSuccessSelector = (state: RootStateOrAny) => state.agentsReducer.agentDeleteSuccess;
