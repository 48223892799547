import { Input } from 'antd';
import React, { forwardRef } from 'react';
import ReactInputMask from 'react-input-mask';
import { IObjectKeys } from '../../../helpers/globalTypes';

const MaskedInput = forwardRef((props: IObjectKeys, ref) => {
  return (
    <ReactInputMask mask={props.mask} {...props}>
      {(inputProps: any) => (
        <Input
          {...inputProps}
          ref={ref}
          disabled={props.disabled ? props.disabled : null}
        />
      )}
    </ReactInputMask>
  );
});

export default MaskedInput;
