import * as types from './types';

const initialState: any = {
  listings: [],
  listingsLoading: false,
  listingPublishStateLoading: false,
  listingsError: null,
  listingDeleteSuccess: false,
  total: 0,
};

export default function listingsReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {

    case types.FORCE_DELETE_LISTING:
      return {
        ...state,
        listingsError: null,
        listingsLoading: true,
        listingDeleteSuccess: false
      };
    case types.FORCE_DELETE_LISTING_SUCCESS:
      return {
        ...state,
        listingsLoading: false,
        listingDeleteSuccess: true
      };
    case types.FORCE_DELETE_LISTING_FAIL:
      return {
        ...state,
        listingsLoading: false,
        listingDeleteSuccess: false,
        listingsError: action.payload,
      };

    case types.TOGGLE_LISTING_PUBLISH_STATE:
      return {
        ...state,
        listingsError: null,
        listingPublishStateLoading: action.payload.listingId
      };

    case types.TOGGLE_LISTING_PUBLISH_STATE_SUCCESS:
      return {
        ...state,
        listingPublishStateLoading: false
      };

    case types.TOGGLE_LISTING_PUBLISH_STATE_FAIL:
      return {
        ...state,
        listingPublishStateLoading: false,
        listingsError: action.payload
      };

    case types.GET_LISTINGS:
      return {
        ...state,
        listingsLoading: true,
        listingsError: null,
        listingDeleteSuccess: false
      };

    case types.GET_LISTINGS_SUCCESS:
      return {
        ...state,
        listings: action.payload.data,
        total: action.payload.metadata[0].total,
        listingsLoading: false,
      };

    case types.GET_LISTINGS_FAIL:
      return {
        ...state,
        listingsError: action.payload,
        total: 0,
        listingsLoading: false,
      };

    default:
      return state;
  }
}
