import * as types from './types';
import { UserModalFormData } from '../../components/Users/types';
import { UserDto } from '../../types/user';

export function getUsers(
  page: number = 1,
  size: number = 10,
  sortField: string = '',
  sortOrder: string = '',
  searchValue: string = '',
  agentsOnly: boolean = false,
) {
  return {
    type: types.GET_USERS,
    payload: { page, size, sortField, sortOrder, searchValue, agentsOnly },
  };
}

export function getUsersSuccess(users: object) {
  return { type: types.GET_USERS_SUCCESS, payload: users };
}

export function getUsersFail(error: object) {
  return { type: types.GET_USERS_FAIL, payload: error };
}
export function updateUser(payload: UserModalFormData) {
  return {
    type: types.UPDATE_USER,
    payload
  };
}

export function updateUserSuccess(payload: any) {
  return {
    type: types.UPDATE_USER_SUCCESS,
    payload
  };
}
export function updateUserFail(payload: UserModalFormData) {
  return {
    type: types.UPDATE_USER_FAIL,
    payload
  };
}
export function addUser(payload: UserModalFormData) {
  return {
    type: types.ADD_USER,
    payload
  };
}

export function softDeleteUserSuccess(payload: UserDto) {
  return {
    type: types.SOFT_DELETE_USER_SUCCESS,
    payload
  };
}
export function softDeleteUserFail(payload: any) {
  return {
    type: types.SOFT_DELETE_USER_FAIL,
    payload
  };
}
export function softDeleteUser(payload: string) {
  return {
    type: types.SOFT_DELETE_USER,
    payload
  };
}

export function forceDeleteUserSuccess(payload: UserDto) {
  return {
    type: types.FORCE_DELETE_USER_SUCCESS,
    payload
  };
}
export function forceDeleteUserFail(payload: any) {
  return {
    type: types.FORCE_DELETE_USER_FAIL,
    payload
  };
}
export function forceDeleteUser(payload: string) {
  return {
    type: types.FORCE_DELETE_USER,
    payload
  };
}

export function recoverUserSuccess(payload: string) {
  return {
    type: types.RECOVER_USER_SUCCESS,
    payload
  };
}
export function recoverUserFail(payload: any) {
  return {
    type: types.RECOVER_USER_FAIL,
    payload
  };
}
export function recoverUser(payload: string) {
  return {
    type: types.RECOVER_USER,
    payload
  };
}

export function addUserSuccess(payload: any) {
  return {
    type: types.ADD_USER_SUCCESS,
    payload
  };
}
export function addUserFail(payload: UserModalFormData) {
  return {
    type: types.ADD_USER_FAIL,
    payload
  };
}
