import React, { useEffect, useState } from 'react';
import { Button, Layout, Menu } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import routes, { RouteInterface } from '../../routes/routesCode';
import './AuthLayout.scss';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {signOut} from '../../store/user/action';

const { Header, Sider, Content } = Layout;

export interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = (props: AuthLayoutProps) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState('/');

  useEffect(() => {
    const splitLocation: string[] = pathname.split('/');
    setActiveMenuItem('/' + (splitLocation[1] ? splitLocation[1] : ''));
  }, [pathname]);

  const toggleNavBar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <Layout className='auth-layout-wrapper'>
      <Sider trigger={null} collapsible collapsed={collapsed} className='auth-layout-sider'>
        <div className={'logo' + (collapsed? ' collapsed' : '')}> <span className='font-24'>{!collapsed ? 'RentBase': 'RB'}</span> </div>
        <Menu
          theme={'dark'}
          selectedKeys={[activeMenuItem]}
          mode='inline'
          onClick={(entity) => {
            setActiveMenuItem(entity.key.toString());
          }}
        >
          {Object.values(routes)
            .filter((item: RouteInterface) => !item.public)
            .map((item: RouteInterface) => {
              return (
                <Menu.Item key={item.path} icon={item.icon} onClick={() => dispatch(push(item.path))}>
                  {item.title}
                </Menu.Item>
              );
            })}
        </Menu>
      </Sider>
      <Layout className='site-layout'>
        <Header className='header-layout d-flex justify-between align-center'>
          {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: 'trigger',
            onClick: toggleNavBar,
          })}
          <Button
            type='primary'
            size='middle'
            onClick={() => {
              dispatch(signOut())
            }}
          >
            Sign Out
          </Button>
        </Header>
        <Content
          className='site-layout-background'
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default AuthLayout;
