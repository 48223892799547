import { IObjectKeys } from '../../helpers/globalTypes';
import React from 'react';

export interface UserModalFormData extends IObjectKeys {
  _id?: string,
  name?: string,
  email?: string,
  phone?: string,
  renter?: boolean,
  landlord?: boolean,
  agent?: boolean,
  profiles?: {
    [key: string]: boolean;
    landlord: boolean,
    renter: boolean,
    agent: boolean
  }
}
export interface actionButtonsPropsDto {
  openEditModal?: (userData: UserModalFormData) => void;
  userData: IObjectKeys
}

export interface ModalDTO {
  visible: boolean;
  children?: React.ReactNode;
  title?: string | React.ReactNode;
  className?: string;
  onCancel?: () => void,
  actionButtons?: React.ReactNode[];
}

export enum SubscriptionStatusEnum {
  trial = 'Trial',
  subscribed = 'Subscribed'
}
