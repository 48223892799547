import * as React from 'react';
import { Modal, Button } from 'antd';
import { DeleteOutlined, RollbackOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { ModalDTO } from '../types';
import { actionButtonsPropsDto } from '../types';
import { useDispatch } from 'react-redux';
import { recoverUser, softDeleteUser, forceDeleteUser } from '../../../store/users/action';

const { confirm } = Modal;
const ActionsCell = ({ userData, openEditModal }: actionButtonsPropsDto) => {
  const dispatch = useDispatch();
  const openDeleteConfirm = () => {
    confirm({
      title: 'Are You Sure you want to delete this User?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(softDeleteUser(userData._id));
      },
    });
  };

  const openForceDeleteConfirm = () => {
    confirm({
      title: 'Are You Sure you want to delete this User with all related entities?',
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(forceDeleteUser(userData._id));
      },
    });
  };

  const openRecoverConfirm = () => {
    confirm({
      title: 'Do you want to recover this User?',
      icon: <RollbackOutlined />,
      onOk() {
        dispatch(recoverUser(userData._id));
      },
    });
  };

  const buttons: any = [];
  if (!userData.is_active) {
    buttons.unshift(
      <Button
        key='rollbackButton'
        className='flex-centered mr-25 recover-icon-color'
        onClick={() => openRecoverConfirm()}
      >
        <RollbackOutlined className='font-24' />
      </Button>,
    );
  } else {
    buttons.unshift(
      <Button
        key={`${userData._id}'editButton`}
        className='flex-centered edit-icon-color mr-25'
        onClick={() => {
          openEditModal && openEditModal({
            _id: userData._id,
            email: userData.email,
            phone: userData.phone,
            name: userData.name,
            profiles: {
              landlord: !!userData.landlords?.length,
              renter: !!userData.renter?.length,
              agent: !!userData.agents?.length,
            },
          })
        }}
      >
        <EditOutlined className='font-24' />
      </Button>,
      <Button
        key={`${userData._id}'deleteButton`}
        className='flex-centered delete-icon-color soft-delete-user'
        onClick={() => openDeleteConfirm()}
      >
        <DeleteOutlined className='font-24' />
      </Button> ,
      <Button
          key={`${userData._id}'forceDeleteButton`}
          className='flex-centered delete-icon-color force-delete-user'
          onClick={() => openForceDeleteConfirm()}
        >
          <DeleteOutlined className='font-24' />
        </Button>
    );
  }
  return (
    <div className='flex-centered'>
      {buttons.map((b: React.ReactNode) => b)}
    </div>
  );
};

export default ActionsCell;
