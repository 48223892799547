import * as types from './types';

export function getListings(
  page: number = 1,
  size: number = 10,
  sortField: string = '',
  sortOrder: string = '',
  searchValue: string = ''
) {
  return {
    type: types.GET_LISTINGS,
    payload: { page, size, sortField, sortOrder, searchValue },
  };
}

export function getListingsSuccess(data: object) {
  return {
    type: types.GET_LISTINGS_SUCCESS,
    payload: data
  };
}

export function getListingsFail(error: object) {
  return {
    type: types.GET_LISTINGS_FAIL,
    payload: error
  };
}

export function forceDeleteListing(payload: string) {
  return { type: types.FORCE_DELETE_LISTING, payload };
}

export function forceDeleteListingSuccess(payload: any) {
  return { type: types.FORCE_DELETE_LISTING_SUCCESS, payload };
}

export function forceDeleteListingFail(payload: any) {
  return { type: types.FORCE_DELETE_LISTING_FAIL, payload };
}

export function toggleListingPublishState(payload: any) {
  return { type: types.TOGGLE_LISTING_PUBLISH_STATE, payload };
}

export function toggleListingPublishStateSuccess() {
  return { type: types.TOGGLE_LISTING_PUBLISH_STATE_SUCCESS };
}

export function toggleListingPublishStateFail(error: object) {
  return { type: types.TOGGLE_LISTING_PUBLISH_STATE_FAIL, payload: error };
}
