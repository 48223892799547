import axios, { Method } from 'axios';
import { getAccessToken } from '../helpers/utils';

export interface ApiQueryParams {
  params?: any;
  data?: any;
  headers?: any;
}

export type ApiPrefix = string | number;
export type TokenType = string | null;

class Api {
  url: string;

  constructor(resource: string) {
    this.url = `${process.env.REACT_APP_BASE_URL || 'https://rentbase-api.herokuapp.com'}/${resource}`;
  }

  list = async (params: ApiQueryParams) => {
    return this.buildQuery('get', params);
  };

  get = async (id: ApiPrefix, params: ApiQueryParams) => {
    return this.buildQuery('get', params, id);
  };

  create = async (params: ApiQueryParams) => {
    return this.buildQuery('post', params);
  };

  put = async (id: ApiPrefix, params: ApiQueryParams) => {
    return this.buildQuery('put', params, id);
  };

  destroy = async (id: ApiPrefix, params: ApiQueryParams = {}) => {
    return this.buildQuery('delete', params, id);
  };

  buildQuery = async (method: Method, params: ApiQueryParams, prefix?: ApiPrefix) => {
    const token: TokenType = getAccessToken();
    return axios({
      url: this.getFullUrl(prefix),
      method,
      data: params.data,
      params: params.params,
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    });
  };

  getFullUrl = (prefix?: ApiPrefix) => {
    return `${this.url}${prefix ? `/${prefix}` : ''}`;
  };

}

export default Api;
