import React from 'react';

const GroupsOfAgents = ({ groupsOfAgent }: any) => {
  return (
    <div>
      {
        groupsOfAgent.map((group: any, i: number) => {
          return (
            <span key={group._id}>
              {group.agents_group_name}
              {(i !== (groupsOfAgent.length - 1)) && <span>,   </span>}
            </span>
          );
        })
      }
    </div>
  );
};

export default GroupsOfAgents;
