import { RuleRender } from 'antd/es/form';
import { sanitizePhoneNumber } from './globalFunctions';

export const EMAIL_VALIDATION = {
  required: true,
  pattern: {
    value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
    message: 'Entered value does not match email format',
  },
};
export const NOT_REQUIRED_PHONE = {
  pattern: {
    value: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im,
    message: 'Please write in this format (123) 456-7890',
  },
};
export const PHONE_NUMBER_VALIDATION: RuleRender = ({ getFieldValue }) => ({
  validator(_, value) {
    const sanitizedPhone = value ? sanitizePhoneNumber(value) : '';
    if (sanitizedPhone.length === 10) {
      return Promise.resolve();
    }
    return Promise.reject('Please input valid phone number');
  },
});
export const NOT_REQUIRED_PHONE_VALIDATION: RuleRender = ({ getFieldValue }) => ({
  validator(_, value) {
    const sanitizedPhone = value ? sanitizePhoneNumber(value) : '';
    if (sanitizedPhone.length === 10 || sanitizedPhone.length === 0) {
      return Promise.resolve();
    }
    return Promise.reject(
      'Please input valid phone number'
    );
  },
});
export const REQUIRED_NAME_VALIDATION = {
  required: true,
  minLength: 4,
  message: 'Min length is 4 characters.',
};
export const REQUIRED = { required: true };
export const REQUIRED_WITH_MESSAGE = (fieldName: string) => ({
  required: true,
  message: `${fieldName} is required.`,
});
