import * as types from './types';

const initialState: any = {
  user: {},
  authLoading: false,
  authError: null,
};

export default function adminReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.GET_LOGGED_IN_USER:
    case types.SIGN_IN:
      return {
        ...state,
        authLoading: true,
        authError: null
      };
    case types.GET_LOGGED_IN_USER_SUCCESS:
    case types.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: { id: action.payload._id, email: action.payload.email },
        authLoading: false,
      };
    case types.GET_LOGGED_IN_USER_FAIL:
    case types.SIGN_IN_FAIL:
      return {
        ...state,
        authError: action.payload,
        authLoading: false
      };
    case types.SIGN_OUT:
      return {
        ...state,
        authLoading: true
      };
    case types.SIGN_IN_AS_AGENT:
      return {
        ...state,
        authLoading: true,
        authError: null
      };
    case types.SIGN_IN_AS_AGENT_SUCCESS:
      return {
        ...state,
        authLoading: false
      };
    case types.SIGN_IN_AS_AGENT_FAIL:
      return {
        ...state,
        authLoading: false,
        authError: action.payload
      };
    case types.SIGN_OUT_SUCCESS:
      return {
        ...state,
        user: {},
        authLoading: false
      };
    default:
      return state;
  }
}
