import Api from './api';
import {IObjectKeys} from "../helpers/globalTypes";

export default class AgentActivities {
    static getAgentActivities (params: IObjectKeys) {
        const api = new Api('activities');
        return api.get('', {params})
    }
}

