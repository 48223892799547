export const setAccessToken = (token: string, remember = false) => {
    return localStorage.setItem('access_token', token);
};
export const setRefreshToken = (token: string, remember = false) => {
    return localStorage.setItem('refresh_token', token);
};
export const clearLocalStorage = () => {
    return localStorage.clear();
};
export const getAccessToken = () => {
    return localStorage.getItem('access_token');
};
export const getRefreshToken = () => {
    return localStorage.getItem('refresh_token');
};
