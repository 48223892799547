import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

export interface GuestLayoutProps {
  children: React.ReactNode;
}


const GuestLayout: React.FC<GuestLayoutProps> = ({ children }) => {
  const { location } = useSelector((state: RootStateOrAny) => state.router);
  return (
      <Box height='100vh' display='flex'>
        {children}
      </Box>
  );
};

export default GuestLayout;
