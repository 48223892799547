import { RootStateOrAny } from 'react-redux';

export const agentsGroupsSelector = (state: RootStateOrAny) => state.agentsGroupReducer.agentsGroups;
export const agentsGroupErrorSelector = (state: RootStateOrAny) => state.agentsGroupReducer.agentsGroupError;
export const agentsGroupSaveSuccessSelector = (state: RootStateOrAny) => state.agentsGroupReducer.agentsGroupSaveSuccess;
export const softDeleteAgentsGroupSuccessSelector = (state: RootStateOrAny) => state.agentsGroupReducer.softDeleteAgentsGroupSuccess;
export const agentsGroupUpdateSuccessSelector = (state: RootStateOrAny) => state.agentsGroupReducer.agentsGroupUpdateSuccess;
export const agentsGroupLoadingSelector = (state: RootStateOrAny) => state.agentsGroupReducer.agentsGroupLoading;
export const agentsGroupsTotalSelector = (state: RootStateOrAny) => state.agentsGroupReducer.total;
export const agentsSelector = (state: RootStateOrAny) => state.agentsGroupReducer.agents;
export const agentsErrorSelector = (state: RootStateOrAny) => state.agentsGroupReducer.agentsError;
export const agentsLoadingSelector = (state: RootStateOrAny) => state.agentsGroupReducer.agentsLoading;
export const subscriptionSuccessSelector = (state: RootStateOrAny) => state.agentsGroupReducer.subscriptionSuccess;
