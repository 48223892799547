export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const SIGN_IN_AS_AGENT = 'SIGN_IN_AS_AGENT';
export const SIGN_IN_AS_AGENT_SUCCESS = 'SIGN_IN_AS_AGENT_SUCCESS';
export const SIGN_IN_AS_AGENT_FAIL = 'SIGN_IN_AS_AGENT_FAIL';

export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const GET_LOGGED_IN_USER_SUCCESS = 'GET_LOGGED_IN_USER_SUCCESS';
export const GET_LOGGED_IN_USER_FAIL = 'GET_LOGGED_IN_USER_FAIL';
