import React from 'react';

interface ITotal {
  total: number;
}
const Total: React.FC<ITotal> = ({ total }) => {
  return (
    <div className='mb-40'>
      <h3>Total: {total}</h3>
    </div>
  );
};
export default Total;
