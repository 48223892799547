import { IObjectKeys } from "helpers/globalTypes";

export const getAddressComponentByType = (
    place: IObjectKeys,
    fieldName: string,
    longOrShort: string = "long_name",
  ): undefined | IObjectKeys => {
    const townCatchingWords = ["locality", "sublocality", "sublocality_level_1"];
    const adr_comp = place?.address_components?.find((adr_comp: IObjectKeys) => {
      if (fieldName === "locality") {
        return adr_comp.types.find((item: string) => {
          return townCatchingWords.includes(item);
        });
      }
      return adr_comp.types.indexOf(fieldName) > -1;
    });
    if (adr_comp) {
      return adr_comp[longOrShort];
    }
    return undefined;
  };