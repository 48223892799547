import { all, fork, put, takeLatest, call } from 'redux-saga/effects';
import {
  SIGN_OUT,
  SIGN_IN,
  GET_LOGGED_IN_USER, SIGN_IN_AS_AGENT,
} from './types';
import AuthApi from 'api/Auth';
import { push } from 'connected-react-router';
import routes from '../../routes/routesCode';
import { clearLocalStorage, setAccessToken, setRefreshToken } from '../../helpers/utils';
import { getLoggedInUserFail, getLoggedInUserSuccess, signInFail, signInSuccess, signOutSuccess } from './action';
import { RB_PORTAL_URL } from '../../helpers/constants';
import { openNotification } from '../../helpers/globalFunctions';

function* signInSaga({ payload }: any) {
  try {
    const response = yield call(
        AuthApi.logIn, payload
    );
    if (response) {
      const { access_token, refresh_token, ...user } = response;
      yield setAccessToken(response.access_token);
      yield setRefreshToken(response.refresh_token);
      yield put(signInSuccess(user));
      yield put(push(routes.home.path));
    }
  } catch(e) {
    if (e.message){
      openNotification('error', 'Username or Password are incorrect!');
    }
    yield put(signInFail(e));
  }
}

function* getLoggedInUserSaga() {
  try {
    const response = yield call(
        AuthApi.getLoggedInUser
    );
    yield put(getLoggedInUserSuccess(response));
  }catch(e) {
    clearLocalStorage();
    put(push(routes.signIn.path));
    yield put(getLoggedInUserFail(e));
  }
}
function* signInAsAgentSaga({ payload }: any) {
  try {
    const response = yield call(
      AuthApi.signInAsAgent, payload
    );
    window.open(`${RB_PORTAL_URL}/sign-in?signInAsToken=${response.token}`);
  }catch(e) {
    yield put(signInFail(e));
  }
}
function* signOutSaga() {
  yield clearLocalStorage();
  yield put(push(routes.signIn.path));
  yield put(signOutSuccess());
}

function* watchSignIn() {
  yield takeLatest(SIGN_IN, signInSaga);
}
function* watchGetLoggedInUser() {
  yield takeLatest(GET_LOGGED_IN_USER, getLoggedInUserSaga);
}
function* watchSignInAsAgentSaga() {
  yield takeLatest(SIGN_IN_AS_AGENT, signInAsAgentSaga);
}
function* watchSignOut() {
  yield takeLatest(SIGN_OUT, signOutSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchSignIn),
    fork(watchGetLoggedInUser),
    fork(watchSignInAsAgentSaga),
    fork(watchSignOut)
  ]);
}
