import React, { lazy } from 'react';
import { Switch } from 'react-router-dom';

import SignInPage from 'pages/signIn/SignIn';

import AuthRoute from './Auth/AuthRoute';
import GuestRoute from './Guest/GuestRoute';
import routes from './routesCode';
import Dashboard from '../pages/dashboard';
import Users from '../pages/users';
import Listings from '../pages/listings';
import Agents from '../pages/agents';
import AgentsGroups from '../pages/agentsGroups';
import AgentActivities from '../pages/agentActivities';
import Testing from 'pages/testing';

// Authorized pages start
// const HomePage = lazy(() => import('pages/home/Home'));

// Authorized pages end

const Routes: React.FC<any> = () => {
  return (
    <Switch>
      <AuthRoute exact path={routes.home.path} component={Dashboard} />
      <AuthRoute exact path={routes.users.path} component={Users} />
      <AuthRoute exact path={routes.listings.path} component={Listings} />
      <AuthRoute exact path={routes.agents.path} component={Agents} />
      <AuthRoute exact path={routes.agentsGroups.path} component={AgentsGroups} />
      <AuthRoute exact path={routes.agentActivities.path} component={AgentActivities} />
      <AuthRoute exact path={routes.testing.path} component={Testing} />

      <GuestRoute exact path={routes.signIn.path} component={SignInPage} />
    </Switch>
  );
};

export default Routes;
