export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';

export const SOFT_DELETE_USER_SUCCESS = 'SOFT_DELETE_USER_SUCCESS';
export const SOFT_DELETE_USER_FAIL = 'SOFT_DELETE_USER_FAIL';
export const SOFT_DELETE_USER = 'SOFT_DELETE_USER';

export const FORCE_DELETE_USER = 'FORCE_DELETE_USER';
export const FORCE_DELETE_USER_SUCCESS = 'FORCE_DELETE_USER_SUCCESS';
export const FORCE_DELETE_USER_FAIL = 'FORCE_DELETE_USER_FAIL';

export const RECOVER_USER_SUCCESS = 'RECOVER_USER_SUCCESS';
export const RECOVER_USER_FAIL = 'RECOVER_USER_FAIL';
export const RECOVER_USER = 'RECOVER_USER';
