import React from 'react';
import {
  DashboardOutlined,
  UserOutlined,
  UnorderedListOutlined,
  GroupOutlined,
  SendOutlined,
} from '@ant-design/icons';
export interface RoutesArrInterface {
  [key: string]: RouteInterface
}
export interface RouteInterface {
  path: string;
  title?: string;
  exact?: boolean,
  icon?: React.ReactNode;
  public?: boolean
}
const routes: RoutesArrInterface = {
  // -------- Authorized routes start -------- //
  home: {
    path: '/',
    title: 'Dashboard',
    exact: true,
    icon:  <DashboardOutlined /> ,
  },
  users: {
    path: '/users',
    title: 'Manage Users',
    exact: true,
    icon:  <UserOutlined />,
  },
  agents: {
    path: '/agents',
    title: 'Agents',
    exact: true,
    icon:  <UserOutlined />,
  },
  listings: {
    path: '/listings',
    title: 'Manage Listings',
    exact: true,
    icon:  <UnorderedListOutlined />
  },
  agentsGroups: {
    path: '/agentGroups',
    title: 'Agent Groups',
    exact: true,
    icon:  <GroupOutlined />
  },
  testing: {
    path: '/testing',
    title: 'Testing',
    exact: true,
    icon:  <SendOutlined /> 
  },
  agentActivities: {
    path: '/activities/:agentIdOfAgent',
    exact: true,
  },
// -------- Authorized routes start -------- //


  // -------- Guest routes start -------- //

  signIn: {
    path: '/sign-in',
    exact: true,
    public: true
  },
  // -------- Guest routes end -------- //
};

export default routes;
