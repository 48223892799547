import * as types from './types';

const initialState: any = {
  agentsGroups: [],
  agentsGroupLoading: false,
  agentsGroupError: null,
  agentsGroupSaveSuccess: false,
  agentsGroupUpdateSuccess: false,
  softDeleteAgentsGroupSuccess: false,
  agentsLoading: false,
  agentsError: null,
  agents: [],
  total: 0,
  subscriptionSuccess:false
};

export default function agentsGroupReducer(
  state = initialState,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case types.ADD_AGENT_GROUP:
    case types.UPDATE_AGENT_GROUP:
      return {
        ...state,
        agentsGroupUpdateSuccess: false,
        agentsGroupSaveSuccess: false,
        agentsGroupError: null,
        agentsGroupLoading: true,
      };

    case types.ADD_AGENT_GROUP_SUCCESS:
      const agentsGroupCopy = [...state.agentsGroups];
      agentsGroupCopy.unshift(action.payload);
      return {
        ...state,
        agentsGroups: agentsGroupCopy,
        agentsGroupLoading: false,
        agentsGroupError: null,
        agentsGroupSaveSuccess: true,
      };

    case types.UPDATE_AGENT_GROUP_SUCCESS:
      const agentsGroups = state?.agentsGroups?.map((agentsGroup: any) => {
        if (agentsGroup._id === action.payload?._id) {
          return action.payload;
        }
        return agentsGroup;
      });

      return {
        ...state,
        agentsGroups,
        agentsGroupLoading: false,
        agentsGroupError: null,
        agentsGroupUpdateSuccess: true,
      };

    case types.ADD_AGENT_GROUP_FAIL:
    case types.UPDATE_AGENT_GROUP_FAIL:
      return {
        ...state,
        agentsGroupLoading: false,
        agentsGroupError: action.payload,
        agentsGroupUpdateSuccess: false,
      };

    case types.GET_AGENT_GROUPS:
      return {
        ...state,
        agentsGroupLoading: true,
        agentsGroupError: null,
      };

    case types.GET_AGENT_GROUPS_SUCCESS:
      return {
        ...state,
        agentsGroups: action.payload.data,
        total: action.payload.metadata[0].total,
        agentsGroupLoading: false,
      };
      case types.GET_AGENT_GROUPS_FAIL:
      return {
        ...state,
        agentsGroupError: action.payload,
        total: 0,
        agentsGroupLoading: false,
      };
    case types.GET_AGENTS:
      return {
        ...state,
        agentsLoading: true,
        agentsError: null,
      };
    case types.GET_AGENTS_SUCCESS:
      return {
        ...state,
        agentsLoading: false,
        agents: action.payload.data,
      };
    case types.GET_AGENTS_FAIL:
      return {
        ...state,
        agentsLoading: false,
        agentsError: action.payload,
      };
    case types.SOFT_DELETE_AGENT_GROUP:
      return {
        ...state,
        softDeleteAgentsGroupSuccess: false,
        agentsGroupLoading: true,
        agentsError: null,
      };
    case types.SOFT_DELETE_AGENT_GROUP_SUCCESS:
      const _agentsGroups = state.agentsGroups.find((item: any) => item._id === action.payload._id);
      if (_agentsGroups) {
        _agentsGroups.is_active = false;
      }
      return {
        ...state,
        agentsGroupLoading: false,
        softDeleteUserSuccess: true,
      };
    case types.SOFT_DELETE_AGENT_GROUP_FAIL:
      return {
        ...state,
        softDeleteAgentsGroupSuccess: false,
        agentsLoading: false,
        agentsError: action.payload,
      };
    case types.SET_AGENT_GROUP_TO_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          subscriptionSuccess:true
        };
    case types.CANCEL_AGENT_GROUP_SUBSCRIPTION_SUCCESS:
        return {
          ...state,
          subscriptionSuccess:true
        };
    case types.SET_STATUS_TO_FALSE:
      return {
        ...state,
        subscriptionSuccess:false
      };
    default:
      return state;
  }
}
