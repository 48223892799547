import React, { useEffect, Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { Modal, Form, Select, Input, Button, Spin } from 'antd';

import { IObjectKeys } from '../../../helpers/globalTypes';

import { UserModalFormData } from '../../Users/types';


import {
  groupsOfAgentSelector,
  groupsOfAgentLoadingSelector,
  agentUpdateSuccessSelector,
} from 'store/agents/selectors';
import {
  getGroupsOfAgent,
  cleanDebounce,
  updateAgent,
  addAgent,
} from 'store/agents/action';
import { PHONE_NUMBER_MASK, PHONE_NUMBER_PLACEHOLDER, USER_MODAL_PHONE_OR_EMAIL } from '../../../helpers/constants';
import { NOT_REQUIRED_PHONE_VALIDATION, PHONE_NUMBER_VALIDATION, REQUIRED_NAME_VALIDATION } from '../../../helpers/validations';
import MaskedInput from '../../Shared/MaskedPhoneInput';
import { sanitizePhoneNumber } from '../../../helpers/globalFunctions';
import PlacesAutocomplete, { AddressChangeParams } from 'components/Shared/PlacesAutocomplete/PlacesAutocomplete';

interface UserModalInterface {
  userData: UserModalFormData;
  modalType: string;
  visible: boolean;
  setVisible: (visibleState: boolean) => void;
}

interface agentFormData extends IObjectKeys {
  name: string;
  phone: string;
  email: string;
}

const { Option } = Select;

const AgentModal: React.FC<UserModalInterface> = ({ userData, visible, setVisible, modalType }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const groupsOfAgent = useSelector(groupsOfAgentSelector);
  const groupsOfAgentLoading = useSelector(groupsOfAgentLoadingSelector);
  const agentUpdateSuccess = useSelector(agentUpdateSuccessSelector);
  const debounceLoadData = useCallback(debounce((size: number, searchValue: string) => dispatch(getGroupsOfAgent(size, searchValue, true)), 1000), []);
  const [resetPlacesAutocomplete, setResetPlacesAutocomplete] = useState(false);

  useEffect(() => {
    if (agentUpdateSuccess) {
      dispatch(cleanDebounce());
      form.resetFields();
      dispatch(getGroupsOfAgent(10, '', true));
    }
  }, [agentUpdateSuccess]);

  useEffect(() => {
    if (groupsOfAgent && groupsOfAgent.length === 0) {
      dispatch(getGroupsOfAgent(10, '', true));
    }
  }, []);

  useEffect(() => {
    if (userData && Object.keys(userData).length) {
      const { name, phone, email } = userData;
      form.setFieldsValue({ name, phone, email });
      if (userData?.groupsOfAgent['agents_group_name']) {
        form.setFieldsValue({ 'groupOfAgent': userData?.groupsOfAgent['agents_group_name'] });
      }
    } else {
      form.resetFields();
    }
  }, [form, userData, agentUpdateSuccess]);

  const resetPhoneOrEmailError = () => {
    form.setFields([
      {
        name: 'phone',
        errors: [],
      },
      {
        name: 'email',
        errors: [],
      },
    ]);
  };

  const onAddressChange = (address: AddressChangeParams) => {

    let setFieldValues: IObjectKeys = {
      address: address.formatted_address
    };
    setFieldValues.defaultState =
      address.separated_fields?.state ?
        address.separated_fields?.state : "";

    setFieldValues.defaultCity =
      address.separated_fields?.town ?
        address.separated_fields?.town : "";

    form.setFieldsValue(setFieldValues)

  }

  const handleSubmit = (formData: agentFormData) => {

    if (!formData.profiles) formData.profiles = [];
    if (!formData.email && !formData.phone) {
      form.setFields([
        {
          name: 'email',
          errors: [USER_MODAL_PHONE_OR_EMAIL],
        },
        {
          name: 'phone',
          errors: [USER_MODAL_PHONE_OR_EMAIL],
        },
      ]);

      return;
    }
    if (formData.phone) {
      formData.phone = sanitizePhoneNumber(formData.phone);
    }
    if (userData._id) {

      dispatch(updateAgent({
        _id: userData._id,
        ...formData,
        phone: formData.phone || undefined
      }));
      // if (agentGroupsToUpdate.length) {

      // } else {
      //   dispatch(updateAgent({
      //     ...formData,
      //     _id: userData._id,
      //   }));
      // }
    } else {
      const profiles: any = { agent: true, renter: false, landlord: false };
      dispatch(addAgent({
        ...formData,
        phone: formData.phone || undefined,
        profiles,
      }));
    }
  };
  return (
    <Modal
      title={(userData && userData._id ? 'Edit ' : 'Add ') + modalType}
      footer={null}
      visible={visible}
      width={320}
      onCancel={() => {
        form.resetFields();
        setVisible(false);
      }}
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
      >
        <Form.Item label='Name' name='name'
          rules={[{ required: true, min: 4, max: 20, message: 'Required field, format - John Doe' }]}>
          <Input placeholder='Name' />
        </Form.Item>
        <Form.Item
          name='phone'
          label='Phone Number'
          rules={[NOT_REQUIRED_PHONE_VALIDATION]}
          validateTrigger={'onBlur'}
        >
          <MaskedInput
            mask={PHONE_NUMBER_MASK}
            placeholder={PHONE_NUMBER_PLACEHOLDER}
          />
        </Form.Item>
        <Form.Item
          label='Email'
          name='email'
          rules={[{ required: true, type: 'email', message: 'Required field, format - mail@gmail.com' }]}>
          <Input
            placeholder='Email'
            onChange={() => resetPhoneOrEmailError()}
          />
        </Form.Item>

        <Form.Item
          label='Groups Of Agent'
          name='groupOfAgent' rules={[{ required: true, message: 'Agent Group is required.' }]}
        >
          <Select
            showSearch
            placeholder='Select Group'
            notFoundContent={groupsOfAgentLoading ? <Spin size='small' /> : null}
            filterOption={false}
            onSearch={(value: string) => {
              debounceLoadData(10, value);
            }}
          >
            {
              groupsOfAgent.map((group: any) => {
                return (
                  <Option key={group._id} value={group._id}>{group.agents_group_name}</Option>
                );
              },
              )
            }
          </Select>
        </Form.Item>

        {
          !(userData && userData._id) && <Fragment>
            <Form.Item label='Invitation Text' name='invitationText'
              rules={[{ required: true, type: 'string', message: 'Required Field.' }]}>
              <Input.TextArea placeholder='Invitation Text' />
            </Form.Item>
          </Fragment>
        }

        <Form.Item
          name="address"
          label="Enter city and state"
          rules={[REQUIRED_NAME_VALIDATION]}
        >
          <PlacesAutocomplete
            onAddressChange={onAddressChange}
            resetPlacesAutocomplete={resetPlacesAutocomplete}
            setResetPlacesAutocomplete={setResetPlacesAutocomplete}
          />
        </Form.Item>
        <Form.Item name="defaultState" hidden={true}></Form.Item>
        <Form.Item name="defaultCity" hidden={true}></Form.Item>

        <Form.Item>
          <Button type='primary' htmlType='submit'>{(userData && userData._id ? 'Save' : 'Add')}</Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default AgentModal;
