import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Select } from 'antd';
import {
  agentsLoadingSelector,
  allAgentsSelector,
  followUpLoadingSelector,
} from '../../store/agents/selectors';
import { IObjectKeys } from 'helpers/globalTypes';
import { getAllAgents, sendWeeklyFollowUps } from 'store/agents/action';
import { REQUIRED_WITH_MESSAGE } from 'helpers/validations';
import { userSelector } from 'store/user/selectors';
import { IFollowUpSend } from 'components/Users/types';

const Testing = () => {
  const allAgents = useSelector(allAgentsSelector);
  const user = useSelector(userSelector);
  const followUpLoading = useSelector(followUpLoadingSelector);
  const getAgentsLoading = useSelector(agentsLoadingSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllAgents());
  }, []);

  const handleSubmit = (values: IFollowUpSend) => {
    const data = {
      ...values,
      receiverEmail: user.email,
    };
    dispatch(sendWeeklyFollowUps(data));
  };
  return (
    <div className='page-header-wrapper flex-col align-start testing'>
      <h1 className='font-24'>Simulate weekly follow-ups</h1>
      <div className='display-flex'>
        <Form onFinish={handleSubmit}>
          <Form.Item
            name='userId'
            rules={[REQUIRED_WITH_MESSAGE('This field')]}
            className='w-22'
            style={{ marginBottom: 20 }}
          >
            <Select
              showSearch
              placeholder='Select an agent'
              optionFilterProp='children'
              size='large'
              className='testing-search-select w-22'
              loading={getAgentsLoading}
            >
              {allAgents.length &&
                allAgents.map((agent: IObjectKeys) => (
                  <Select.Option value={agent._id} key={agent._id}>
                    {agent.name}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              size='large'
              htmlType='submit'
              loading={followUpLoading}
              block
            >
              Send to me
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
export default Testing;
