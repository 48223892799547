import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import AgentActivities from '../../api/AgentActivities';

import { GET_AGENT_ACTIVITIES, } from './types';
import { getAgentsActivitiesFail, getAgentsActivitiesSuccess, } from './action';

function* getAgentActivitiesSaga({ payload }: any) {
  try {
    const response = yield call(
      AgentActivities.getAgentActivities, { ...payload, sortOrder: payload.sortOrder, sortField: payload.sortField },
    );
    yield put(getAgentsActivitiesSuccess(response));
  } catch (e) {
    yield put(getAgentsActivitiesFail(e));
  }
}

function* watchAgentActivitiesSaga() {
  yield takeLatest(GET_AGENT_ACTIVITIES, getAgentActivitiesSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchAgentActivitiesSaga),
  ]);
}
