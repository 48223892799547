import * as React from 'react';
import { Button } from 'antd';
import { DeleteOutlined, CloudUploadOutlined, CloudDownloadOutlined } from '@ant-design/icons';

const ActionsCell = (props: any) => {
  const { rowData, openDeleteConfirmModal, listingPublishStateLoading, onTogglePublishState } = props;
  return (
    <div className='flex-centered'>
      <Button
        key='deleteButton'
        className='flex-centered delete-icon-color mr-25'
        onClick={() => {
          openDeleteConfirmModal({
            addr: rowData.addr,
            _id: rowData._id
          });
        }}
      >
        <DeleteOutlined className='font-24'/>
      </Button>
      <Button
        key='togglePublishStateButton'
        className='flex-centered delete-icon-color mr-25'
        loading={listingPublishStateLoading}
        onClick={() => {
          const action = rowData.published_rdt ? 'unpublish' : 'publish';
          onTogglePublishState(rowData._id, action);
        }}
      >
        {!rowData.published_rdt && <><CloudUploadOutlined className='font-24' label="RDT"/> Publish RDT</>}
        {rowData.published_rdt && <><CloudDownloadOutlined className='font-24'/> Unpublish RDT</>}
      </Button>
    </div>
  );
};

export default ActionsCell;
