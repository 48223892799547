import { all } from 'redux-saga/effects';
import authSaga from 'store/user/sagas';
import usersSaga from 'store/users/sagas';
import agentsGroup from 'store/agentsGroups/sagas';
import agentsSaga from 'store/agents/sagas';
import listingsSaga from 'store/listings/sagas';
import agentActivitiesSaga from 'store/agentActivities/sagas'

export default function* rootSaga() {
  yield all([
    authSaga(),
    usersSaga(),
    agentsGroup(),
    agentsSaga(),
    listingsSaga(),
    agentActivitiesSaga()
  ]);
}


