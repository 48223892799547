import React, { useEffect, useState } from "react";
import { StandaloneSearchBox } from "@react-google-maps/api";
import { Input } from "antd";
import { getAddressComponentByType } from "./helpers";
import { IObjectKeys } from "../../../helpers/globalTypes";


export const address_fields: IObjectKeys = {
    street_number: "street_number",
    street_name: "route",
    state: "administrative_area_level_1",
    short_state: "administrative_area_level_1",
    town: "locality",
    postal_code: "postal_code"
  };
export type LocationParams = { lng?: number, lat?: number };
export interface AddressChangeParams {
    formatted_address: string | undefined;
    separated_fields?: IObjectKeys,
    location?: LocationParams
}
export interface IPlacesAutocomplete {
    defaultAddress?: string,
    onAddressChange: (addressParams: AddressChangeParams) => void
    resetPlacesAutocomplete: boolean,
    setResetPlacesAutocomplete: Function
}

const PlacesAutocomplete = ({
  defaultAddress,
  onAddressChange,
  resetPlacesAutocomplete,
  setResetPlacesAutocomplete,
}: IPlacesAutocomplete) => {
  const [autocompleteState, setAutocompleteState] = useState(null);
  const [
    addressWithLocation,
    setAddressWithLocation,
  ] = useState<AddressChangeParams>({
    formatted_address: defaultAddress,
    separated_fields: {},
    location: {},
  });
  const [inputWasTouched, setInputWasTouched] = useState(false);
  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setAddressWithLocation({ formatted_address: value, location: {} });
  };

  const onPlacesChanged = () => {
    // @ts-ignore
    const foundedPlace = autocompleteState?.getPlaces();
    if (foundedPlace?.[0]?.formatted_address) {
      const fieldsToSet: IObjectKeys = {};
      for (const [key, value] of Object.entries(address_fields)) {
        fieldsToSet[key] = getAddressComponentByType(foundedPlace[0], value, key.includes('short') ? 'short_name' : 'long_name');
      }

      setAddressWithLocation({
        formatted_address: foundedPlace[0].formatted_address,
        separated_fields: fieldsToSet,
        location: {
          lng: foundedPlace[0].geometry.location.lng(),
          lat: foundedPlace[0].geometry.location.lat(),
        },
      });
    }
  };

  const onLoadSearch = (places: any) => {
    setAutocompleteState(places);
  };

  const returnAddressValue = () => {
    if (!inputWasTouched) {
      return addressWithLocation.formatted_address || defaultAddress
    }
    return addressWithLocation.formatted_address
  }

  useEffect(() => {
    
    if (!defaultAddress) {
      setAddressWithLocation({ formatted_address: "", location: {} });
      setResetPlacesAutocomplete(false);
    }
  }, [defaultAddress]);

  useEffect(() => {
    onAddressChange(addressWithLocation);
  }, [addressWithLocation]);

  useEffect(() => {
    if (resetPlacesAutocomplete) {
      setAddressWithLocation({ formatted_address: "", location: {} });
      setResetPlacesAutocomplete(false);
    }
  }, [resetPlacesAutocomplete]);

  return (
    <StandaloneSearchBox
      onLoad={onLoadSearch}
      onPlacesChanged={onPlacesChanged}
    >
      <Input
        data-test-id={"listing-address-input"}
        type="text"
        value={returnAddressValue()}
        placeholder="Enter Address"
        autoComplete={"off"}
        onKeyPress={() => { }}
        onChange={(e) => {
          inputOnChange(e)
          if (!inputWasTouched) {
            setInputWasTouched(true);
          }
        }}
      />
    </StandaloneSearchBox>
  );
};

export default PlacesAutocomplete;
