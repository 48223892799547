import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import adminReducer from 'store/user/reducer';
import usersReducer from './users/reducer';
import agentsGroupReducer from './agentsGroups/reducer';
import agentsReducer from './agents/reducer';
import listingsReducer from './listings/reducer';
import agentActivitiesReducer from './agentActivities/reducer';


const createRootReducers = (history: any) =>
  combineReducers({
    adminReducer,
    usersReducer,
    agentsGroupReducer,
    agentsReducer,
    listingsReducer,
    agentActivitiesReducer,
    router: connectRouter(history),
  });

export default createRootReducers;

