import { all, fork, put, takeLatest, call } from 'redux-saga/effects';

import ListingsApi from '../../api/Listings';
import { generateErrorMessage, openNotification } from '../../helpers/globalFunctions';

import { FORCE_DELETE_LISTING, GET_LISTINGS, TOGGLE_LISTING_PUBLISH_STATE } from './types';
import {
  getListingsSuccess,
  getListingsFail,
  forceDeleteListingSuccess,
  forceDeleteListingFail,
  toggleListingPublishStateSuccess,
  toggleListingPublishStateFail
} from './action';


function* getListingsSaga({ payload }: any) {
  try {
    const response = yield call(ListingsApi.getListings, payload);
    yield put(getListingsSuccess(response));
  } catch (e) {
    yield put(getListingsFail(e));
  }
}

function* forceDeleteListingSaga({ payload }: any) {
  try {
    const response = yield call(
      ListingsApi.forceDeleteListing, payload,
    );
    yield put(forceDeleteListingSuccess(response));
    openNotification('success', 'Listing deleted successfully');
  } catch (e) {
    openNotification('error', generateErrorMessage(e));
    yield put(forceDeleteListingFail(e));
  }
}

function* toggleListingPublishStateSaga({ payload }: any) {
  try {
    const response = yield call(ListingsApi.togglePublishListingState, payload);
    yield put(toggleListingPublishStateSuccess());
    openNotification('success', 'Listing publish status updated successfully');
  } catch (e) {
    openNotification('error', generateErrorMessage(e) || 'Internal server error');
    yield put(toggleListingPublishStateFail(e));
  }
}

function* watchGetListings() {
  yield takeLatest(GET_LISTINGS, getListingsSaga);
}

function* watchForceDeleteListing() {
  yield takeLatest(FORCE_DELETE_LISTING, forceDeleteListingSaga);
}

function* watchtoggleListingPublishState() {
  yield takeLatest(TOGGLE_LISTING_PUBLISH_STATE, toggleListingPublishStateSaga);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetListings),
    fork(watchForceDeleteListing),
    fork(watchtoggleListingPublishState)
  ]);
}
