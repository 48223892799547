import * as types from './types';
import { AuthError } from '../../types/user';

export const signIn = (payload: {email: string, password: string}) => ({
  type: types.SIGN_IN,
  payload
});
export const signInSuccess = (payload: {_id: string, email: string}) => ({
  type: types.SIGN_IN_SUCCESS,
  payload
});
export const signInFail = (payload: AuthError) => ({
  type: types.SIGN_IN_FAIL,
  payload
});

export const signInAsAgent = (payload: string) => ({
  type: types.SIGN_IN_AS_AGENT,
  payload
});
export const signInAsAgentSuccess = (payload: {token: string}) => ({
  type: types.SIGN_IN_AS_AGENT_SUCCESS,
  payload
});
export const signInAsAgentFail = (payload: AuthError) => ({
  type: types.SIGN_IN_AS_AGENT_FAIL,
  payload
});

export const getLoggedInUser = () => ({
  type: types.GET_LOGGED_IN_USER,
});
export const getLoggedInUserSuccess = (payload: {_id: string, email: string}) => ({
  type: types.GET_LOGGED_IN_USER_SUCCESS,
  payload
});
export const getLoggedInUserFail = (payload: AuthError) => ({
  type: types.GET_LOGGED_IN_USER_FAIL,
  payload
});

export const signOut = () => ({
  type: types.SIGN_OUT,
});
export const signOutSuccess = () => ({
  type: types.SIGN_OUT_SUCCESS,
});
