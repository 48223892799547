import React from 'react';
import { Form, Input, Button, Avatar } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import './styles.scss';
import { AuthFormInterface, AuthFormProps } from './authTypes';
import { useSelector } from 'react-redux';
import { authLoadingSelector } from '../../../store/user/selectors';

const AuthForm = (props: AuthFormProps) => {
  const loading = useSelector(authLoadingSelector);
  const onFinish = (values: AuthFormInterface) => {
    props.onSubmit(values);
  };

  return (
    <div>
      <div className='user-logo-wrapper'>
        <Avatar size={80} icon={<UserOutlined/>} className='user-logo'/>
      </div>
      <Form
        className='login-form'
        onFinish={onFinish}
      >
        <Form.Item
          name='email'
          rules={[
            {
              required: true,
              message: 'Please input your Email!',
            },
          ]}
        >
          <Input size='large' prefix={<UserOutlined className='site-form-item-icon' />} placeholder='Username' />
        </Form.Item>
        <Form.Item
          name='password'
          rules={[
            {
              required: true,
              message: 'Please input your Password!',
            },
          ]}
        >
          <Input
            size='large'
            prefix={<LockOutlined className='site-form-item-icon' />}
            type='password'
            placeholder='Password'
          />
        </Form.Item>

        <Form.Item>
          <Button size='large' type='primary' htmlType='submit' className='login-form-button' loading={loading}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>

  );
};

export default AuthForm;
