import { AxiosPromise } from 'axios';

import { AuthError, UserDto } from '../types/user';
import { IObjectKeys } from '../helpers/globalTypes';

import Api from './api';
import { IFollowUpSend } from 'components/Users/types';


interface agentsData {
  total: number;
  data: UserDto[];
}

export default class AgentsApi {
  static getGroupsOfAgent(params: any): any {
    const api = new Api('agents-group');
    return api.get('', { params });
  }

  static getAgents(params: any): AxiosPromise<agentsData | AuthError> {
    const api = new Api('users');
    return api.get('', { params });
  }

  static getAllAgents(params: any): AxiosPromise<agentsData | AuthError> {
    const api = new Api('admin/agent/select-options');
    return api.get('', { params });
  }

  static addAgent(data: any): AxiosPromise<agentsData | AuthError> {
    const api = new Api('users');
    return api.create({ data });
  }

  static updateAgent(data: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api('users');
    return api.put('', { data: data.data, params: data.params });
  }

  static forceDeleteAgent(agentId: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api('users/force_delete_agent');
    return api.destroy(agentId);
  }

  static deleteAgent(agentId: string): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api('users');
    return api.destroy(agentId);
  }

  static sendFollowUps(
    data: IFollowUpSend,
  ): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api('admin/test-notifications/weekly-followups');
    return api.create({ data });
  }
}

