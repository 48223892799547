import React, { useEffect } from 'react';
import { push } from 'connected-react-router';
import { Route, RouteProps } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import routes from 'routes/routesCode';
import { useAppContext } from 'libs/contextLib';
import GuestLayout from 'layouts/guest/GuestLayout';
import { userSelector } from '../../store/user/selectors';
import { getAccessToken } from '../../helpers/utils';

export interface GuestRouteProps extends RouteProps {
    component: React.JSXElementConstructor<any>;
}

const GuestRoute: React.FC<GuestRouteProps> = ({
                                                   component: Component,
                                                   ...rest
                                               }) => {
    const { isAuthenticated, userHasAuthenticated } = useAppContext();
    const user = useSelector(userSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        async function onLoad() {
            if (user.email || getAccessToken()) {
                userHasAuthenticated(true);
                setTimeout(() => {
                    dispatch(push(routes.home.path));
                }, 50);
            }else {
                userHasAuthenticated(false);
            }
        }
        (async () => await onLoad())();
    }, []);

    return !isAuthenticated ? (
        <Route
            {...rest}
            render={(...renderProps) => (
                <GuestLayout>
                    <Component {...renderProps} />
                </GuestLayout>
            )}
        />
    ) : null;
};

export default GuestRoute;
