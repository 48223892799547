import Api from './api';
import { IObjectKeys } from '../helpers/globalTypes';
import { getRefreshToken, setAccessToken, setRefreshToken } from '../helpers/utils';
import { AxiosPromise } from 'axios';
import { AuthError } from '../types/user';

export default class AuthApi {
  static logIn(data: { email: string, password: string }) {
    const api = new Api('auth/login');
    return api.create({ data });
  }

  static signInAsAgent(userId: string) {
    const api = new Api('auth');
    return api.get('signInAs', { params: { userId } });
  }

  static getLoggedInUser() {
    const api = new Api('auth');
    return api.get('get-user', {});
  }

  static resendConfirmationLink(agent_id: any): AxiosPromise<IObjectKeys | AuthError> {
    const api = new Api(`auth/resendConfirmationLink/${agent_id}`);
    return api.create({});
  }

  static async refreshToken() {
    return new Promise(async (resolve, reject) => {
      try {
        const api = new Api('auth/refreshToken');
        const tokens: IObjectKeys = await api.create({
          data: { refreshToken: getRefreshToken() },
        });
        setAccessToken(tokens.access_token);
        setRefreshToken(tokens.access_token);
        resolve(tokens.access_token);
      } catch(e) {
        reject(e);
      }
    });
  }
}

