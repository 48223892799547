import React from 'react';
import ListingsTable from '../../components/Listings/ListingsTable';

const Listings = () => {
  return (
    <div>
      <h1 className='font-24'>Listings</h1>
      <ListingsTable />
    </div>
  );
};
export default Listings;
