import axios from 'axios';
import store from '../store';
import { signOut } from '../store/user/action';
import AuthApi from '../api/Auth';
import { getRefreshToken } from '../helpers/utils';
const axiosApiInstance = axios.create();
export const configureAxios = () => {
    let tryRefreshToken = true;
    axios.interceptors.request.use(function (config) {
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    axios.interceptors.response.use(function (response) {
        const { data } = response;
        return { ...data };
    }, function (error) {
        const originalRequest = error.config;
        if (error.response.status === 401 &&
          tryRefreshToken &&
          (['login', 'resetPassword'].indexOf(originalRequest.url.split('/').pop()) === -1) &&
          getRefreshToken()) {
            tryRefreshToken = false;
            return (async () => {
                try {
                    const access_token = await AuthApi.refreshToken();
                    originalRequest.headers.Authorization = `Bearer ${access_token}`;
                    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
                    return axiosApiInstance(originalRequest);
                }catch (e) {
                    store.dispatch(signOut());
                }
            })();
        }
        if (error.response) {
            if (error.response.status === 401) {
                store.dispatch(signOut());
            }
            const { response: { data } } = error;
            return Promise.reject({ ...data });
        }
        return Promise.reject(error);
    });
};

